export const LOGIN_LOADING = "LOGIN_LOADING";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPLOAD_PIC_LOADING = "UPLOAD_PIC_LOADING";
export const UPLOAD_PIC_DATA = "UPLOAD_PIC_DATA";
export const FORGOT_PASSWORD_LOADER = "FORGOT_PASSWORD_LOADER";
export const CHANGE_PASSWORD_LOADER = "CHANGE_PASSWORD_LOADER";
export const RESET_PASSWORD_LOADER = "RESET_PASSWORD_LOADER";
export const GET_ALL_PROPERTIES_LOADING = "GET_ALL_PROPERTIES_LOADING";
export const GET_ALL_PROPERTIES_DATA = "GET_ALL_PROPERTIES_DATA";
export const TOTAL_DRAWS_COUNT = "TOTAL_DRAWS_COUNT";
export const GET_COUNTRY_LOADING = "GET_COUNTRY_LOADING";
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";
export const GET_STATES_DATA = "GET_STATES_DATA";
export const GET_STATES_LOADING = "GET_STATES_LOADING";
export const GET_CITIES_LOADING = "GET_CITIES_LOADING";
export const GET_CITIES_DATA = "GET_CITIES_DATA";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const GET_USERS_DETAIL_DATA = "GET_USERS_DETAIL_DATA";
export const GET_USERS_DETAIL_LOADING = "GET_USERS_DETAIL_LOADING";

export const GET_ALL_DEALS_LOADING = "GET_DEALS_LOADING";
export const GET_ALL_DEALS_DATA = "GET_DEALS_DATA";
export const GET_DEALS_DETAIL_LOADING = "GET_DEALS_DETAIL_LOADING";
export const GET_DEALS_DETAIL_DATA = "GET_DEALS_DETAIL_DATA";
export const UPDATE_DEAL_LOADER = "UPDATE_DEAL_LOADER";
export const DELETE_DEAL_LOADER = "DELETE_DEAL_LOADER";
export const DELETE_DEALS_DATA = "DELETE_DEALS_DATA";
export const TOTAL_DEALS_COUNT = "TOTAL_DEALS_COUNT";
export const ADD_DEAL_LOADER = "ADD_DEAL_LOADER";

export const TOTAL_USERS_COUNT = "TOTAL_USERS_COUNT";
export const TOTAL_PROPERTIES_COUNT = "TOTAL_PROPERTIES_COUNT";
export const ADD_PROPERTY_LOADING = "ADD_PROPERTY_LOADING";
export const ADD_PROPERTY_DATA = "ADD_PROPERTY_DATA";
export const UPLOAD_BANNER_PIC_LOADING = "UPLOAD_BANNER_PIC_LOADING";
export const UPLOAD_BANNER_PIC_DATA = "UPLOAD_BANNER_PIC_DATA";
export const DELETE_PROPERTY_LOADING = "DELETE_PROPERTY_LOADING";
export const DELETE_PROPERTY_ID = "DELETE_PROPERTY_ID";
export const UPDATE_PROPERTY_LOADING = "UPDATE_PROPERTY_LOADING";
export const GET_PROPERTY_DETAIL_LOADING = "GET_PROPERTY_DETAIL_LOADING";
export const GET_PROPERTY_DETAIL_DATA = "GET_PROPERTY_DETAIL_DATA";
export const ADD_DRAWS_LOADING = "ADD_DRAWS_LOADING";
export const ADD_DRAWS_DATA = "ADD_DRAWS_DATA";
export const UPLOAD_DRAWS_PIC_LOADING = "UPLOAD_DRAWS_PIC_LOADING";
export const UPLOAD_DRAWS_PIC_DATA = "UPLOAD_DRAWS_PIC_DATA";
export const DELETE_DRAW_LOADING = "DELETE_DRAW_LOADING";
export const DELETE_DRAW_ID = "DELETE_DRAW_ID";
export const GET_DRAW_DETAIL_LOADING = "GET_DRAW_DETAIL_LOADING";
export const GET_DRAW_DETAIL_DATA = "GET_DRAW_DETAIL_DATA";
export const UPDATE_DRAW_LOADING = "UPDATE_DRAW_LOADING";
export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_ID = "DELETE_USER_ID";
export const GET_USER_DETAIL_LOADING = "GET_USER_DETAIL_LOADING";
export const GET_USER_DETAIL_DATA = "GET_USER_DETAIL_DATA";
export const GET_ALL_COUPAN_LOADING = "GET_ALL_COUPAN_LOADING";
export const GET_ALL_COUPAN_DATA = "GET_ALL_COUPAN_DATA";
export const TOTAL_COUPAN_COUNT = "TOTAL_COUPAN_COUNT";
export const DELETE_COUPAN_LOADING = "DELETE_COUPAN_LOADING";
export const DELETE_COUPAN_ID = "DELETE_COUPAN_ID";
export const ADD_COUPAN_LOADING = "ADD_COUPAN_LOADING";
export const ADD_COUPAN_DATA = "ADD_COUPAN_DATA";
export const UPDATE_COUPAN_LOADING = "UPDATE_COUPAN_LOADING";
export const GET_COUPAN_DETAIL_LOADING = "GET_COUPAN_DETAIL_LOADING";
export const GET_COUPAN_DETAIL_DATA = "GET_COUPAN_DETAIL_DATA";
export const GET_CAMPAIGN_DATA = "GET_CAMPAIGN_DATA";
export const GET_CAMPAIGN_LOADING = "GET_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_LOADING = "DELETE_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_ID = "DELETE_CAMPAIGN_ID";
export const ADD_CAMPAIGN_LOADING = "ADD_CAMPAIGN_LOADING";
export const ADD_CAMPAIGN_DATA = "ADD_CAMPAIGN_DATA";
export const UPDATE_CAMPAIGN_LOADING = "UPDATE_CAMPAIGN_LOADING";
export const GET_CAMPAIGN_DETAIL_DATA = "GET_CAMPAIGN_DETAIL_DATA";
export const GET_CAMPAIGN_DETAIL_LOADING = "GET_CAMPAIGN_DETAIL_LOADING";
export const GET_CAMPAIGN_TICKET_LOADING = "GET_CAMPAIGN_TICKET_LOADING";
export const GET_CAMPAIGN_TICKET_DATA = "GET_CAMPAIGN_TICKET_DATA";
export const INCREASE_CAMPAIGN_TICKET_LOADING =
  "INCREASE_CAMPAIGN_TICKET_LOADING";
export const INCREASE_CAMPAIGN_TICKET_DATA = "INCREASE_CAMPAIGN_TICKET_DATA";
export const ALLOWED_REPETATIVE_USERS_LOADING =
  "ALLOWED_REPETATIVE_USERS_LOADING";
export const ALLOWED_REPETATIVE_USERS = "ALLOWED_REPETATIVE_USERS";
export const SEARCH_ANNOUNCE_WINNER_LOADING = "SEARCH_ANNOUNCE_WINNER_LOADING";
export const SEARCH_ANNOUNCE_WINNER_DATA = "SEARCH_ANNOUNCE_WINNER_DATA";
export const ANNOUNCE_WINNER_LOADING = "ANNOUNCE_WINNER_LOADING";
export const ANNOUNCE_WINNER_DATA = "ANNOUNCE_WINNER_DATA";
export const NOT_FOUND_CAMPAIGN_TICKET_DATA = "NOT_FOUND_CAMPAIGN_TICKET_DATA";
export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_DATA = "GET_ORDERS_DATA";
export const TOTAL_ORDERS_COUNT = "TOTAL_ORDERS_COUNT";
export const GET_ORDER_DETAIL_LOADING = "GET_ORDER_DETAIL_LOADING";
export const GET_ORDER_DETAIL_DATA = "GET_ORDER_DETAIL_DATA";
export const SEARCH_ANNOUNCE_DRAW_WINNER_LOADING =
  "SEARCH_ANNOUNCE_DRAW_WINNER_LOADING";
export const SEARCH_ANNOUNCE_DRAW_WINNER_DATA =
  "SEARCH_ANNOUNCE_DRAW_WINNER_DATA";
export const DRAWS_ANNOUNCE_WINNER_LOADING = "DRAWS_ANNOUNCE_WINNER_LOADING";
export const DRAWS_ANNOUNCE_WINNER_DATA = "DRAWS_ANNOUNCE_WINNER_DATA";
export const SEARCH_DRAWS_ANNOUNCE_WINNER_DATA =
  "SEARCH_DRAWS_ANNOUNCE_WINNER_DATA";
export const UPDATE_ORDER_STATUS_LOADING = "UPDATE_ORDER_STATUS_LOADING";
export const GET_ALL_BUSINESS_LOADING = "GET_ALL_BUSINESS_LOADING";
export const GET_ALL_BUSINESS_DATA = "GET_ALL_BUSINESS_DATA";
export const TOTAL_BUSINESS_COUNT = "TOTAL_BUSINESS_COUNT";
export const ADD_BUSINESS_LOADING = "ADD_BUSINESS_LOADING";
export const ADD_BUSINESS_DATA = "ADD_BUSINESS_DATA";
export const GET_BUSINESS_DETAIL_LOADING = "GET_BUSINESS_DETAIL_LOADING";
export const GET_BUSINESS_DETAIL_DATA = "GET_BUSINESS_DETAIL_DATA";
export const UPDATE_BUSINESS_LOADING = "UPDATE_BUSINESS_LOADING";
export const DELETE_BUSINESS_LOADING = "DELETE_BUSINESS_LOADING";
export const DELETE_BUSINESS_ID = "DELETE_BUSINESS_ID";
export const VERIFY_AGENT_LOADING = "VERIFY_AGENT_LOADING";
export const VERIFY_AGENT_ID = "VERIFY_AGENT_ID";
export const UPDATE_ACTIVE_DEACTIVE_BUSINESS_LOADING =
  "UPDATE_ACTIVE_DEACTIVE_BUSINESS_LOADING";
export const UPDATE_ACTIVE_DEACTIVE_BUSINESS =
  "UPDATE_ACTIVE_DEACTIVE_BUSINESS";
export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const VERIFY_AGENT_ROLE_APPROVE = "VERIFY_AGENT_ROLE_APPROVE";
export const GET_FAVOURITE_PROPERTY_LOADING = "GET_FAVOURITE_PROPERTY_LOADING";
export const GET_FAVOURITE_PROPERTY_DATA = "GET_FAVOURITE_PROPERTY_DATA";
export const GET_PROPERTY_DASHBOARD_LOADING = "GET_PROPERTY_DASHBOARD_LOADING";
export const GET_PROPERTY_DASHBOARD_DATA = "GET_PROPERTY_DASHBOARD_DATA";
export const ADD_OWNER_LOADING = "ADD_OWNER_LOADING";
export const ADD_OWNER_DATA = "ADD_OWNER_DATA";
export const DELETE_OWNER_LOADING = "DELETE_OWNER_LOADING";
export const DELETE_OWNER_ID = "DELETE_OWNER_ID";
export const SEND_NOTIFICATION_LOADING = "SEND_NOTIFICATION_LOADING";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const GET_PROPERTY_OPEN_HOUSE_DETAIL_DATA =
  "GET_PROPERTY_OPEN_HOUSE_DETAIL_DATA";
export const GET_PROPERTY_OPEN_HOUSE_DETAIL_LOADING =
  "GET_PROPERTY_OPEN_HOUSE_DETAIL_LOADING";

// Business Users
export const DELETE_BUSINESS_USER_DATA = "DELETE_BUSINESS_USER_DATA";
export const DELETE_BUSINESS_USER_LOADER = "DELETE_BUSINESS_USER_LOADER";
export const UPDATE_BUSINESS_USER_LOADER = "UPDATE_BUSINESS_USER_LOADER";
export const ADD_BUSINESS_USER_LOADER = "ADD_BUSINESS_USER_LOADER";
export const GET_BUSINESS_USER_DETAIL_LOADING =
  "GET_BUSINESS_USER_DETAIL_LOADING";
export const GET_BUSINESS_USER_DETAIL_DATA = "GET_BUSINESS_USER_DETAIL_DATA";
export const GET_ALL_BUSINESS_USER_LOADING = "GET_ALL_BUSINESS_USER_LOADING";
export const GET_ALL_BUSINESS_USER_DATA = "GET_ALL_BUSINESS_USER_DATA";
export const TOTAL_BUSINESS_USER_COUNT = "TOTAL_BUSINESS_USER_COUNT";
export const VERIFY_BUSINESS_LOADING = "VERIFY_BUSINESS_LOADING";
export const VERIFY_BUSINESS_ID = "VERIFY_BUSINESS_ID";
export const APPROVE_BUSINESS_LOADING = "APPROVE_BUSINESS_LOADING ";
export const APPROVE_BUSINESS_ID = "APPROVE_BUSINESS_ID   ";
export const REJECT_BUSINESS_ID = "REJECT_BUSINESS_ID ";
export const REJECT_BUSINESS_LOADING = "REJECT_BUSINESS_LOADING ";

export const GET_DEALS_DASHBOARD_LOADING = "GET_DEALS_DASHBOARD_LOADING ";
export const GET_DEALS_DASHBOARD_DATA = "GET_DEALS_DASHBOARD_DATA ";

export const GET_LEADS_LOADING = "GET_LEADS_LOADING ";
export const GET_LEADS_DATA = "GET_LEADS_DATA ";

export const GET_SINGLE_LEAD_DATA = "GET_SINGLE_LEAD_DATA ";
export const GET_SINGLE_LEADS_LOADING = "GET_SINGLE_LEADS_LOADING ";

export const GET_BILLING_HISTORY_LOADING = "GET_BILLING_HISTORY_LOADING";
export const GET_BILLING_HISTORY_DATA = "GET_BILLING_HISTORY_DATA";

