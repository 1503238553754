import {
  Card,
  Row,
  Col,
  Button,
  Checkbox,
  Form,
  Input,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import logo from "../../assets/img/main-wrapper/logo.png";
import { actionChangePassword } from "../../store/actions/authAction";
import { useNavigate, useParams } from "react-router";
import { connect } from "react-redux";

const { Title } = Typography;

const ChangePassword = (props) => {
  const Navigate = useNavigate();
  const { actionChangePassword, userProfileData, changePasswordLoader } = props;
  const { id } = useParams();

  const [form] = Form.useForm();
  const onFinish = (values) => {
    const reset = () => {
      form.resetFields();
    };
    actionChangePassword(values, reset, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-wrapper">
      <Card
        title={<Title level={3}>Change Password</Title>}
        style={{
          width: 500,
          margin: "auto",
        }}
      >
        <Form
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Row align="center" className="row-card">
            <Col span="24">
              {/* <Form.Item
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Old Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        localStorage.getItem("scoutheimPassword") !== value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "New password cannot be the same as the old password!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Old password" />
              </Form.Item> */}
            </Col>
            <Col span="24">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },

                  {
                    min: 8,
                    message:
                      "passwords must be longer than or equal to 8 characters !",
                  },
                ]}
              >
                <Input.Password placeholder="New password" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                name="confirm_new_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your  new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm New Password" />
              </Form.Item>
            </Col>
            <Col span={24} align="left" style={{ marginTop: "20px" }}>
              <Form.Item>
                <Button loading={changePasswordLoader} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    changePasswordLoader: state.auth.changePasswordLoader,
    userProfileData: state.auth.userProfileData,
  };
};

export default connect(mapStateToProps, { actionChangePassword })(
  ChangePassword
);
