import * as actionTypes from "../action";

const initialState ={
    sendNotificationLoader: false,
    sendNotificationData: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_NOTIFICATION_LOADING: {
            return { ...state, sendNotificationLoader: action.payload };
          }
          case actionTypes.SEND_NOTIFICATION: {
            return { ...state, sendNotificationData: action.payload };
          }
  
      default:
        return state;
    }
  };

  export default notificationReducer;
