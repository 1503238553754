import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actionGetBusinessUserDetail, actionUpdateBusinessUser } from "../../store/actions/businessAction";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EditBusinessRequest = (props) => {

  const { actionGetBusinessUserDetail, updateBusinessUserLoader, actionUpdateBusinessUser, businessUserDetailData, businessUserDetailLoader, } = props;
  const { id } = useParams();


  const Navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      actionGetBusinessUserDetail(id);
    }
  }, [id]);

  useEffect(() => {
    if (businessUserDetailData) {
      form.setFieldsValue({
        first_name: businessUserDetailData.first_name,
        last_name: businessUserDetailData.last_name,
        email: businessUserDetailData.email,
        mobile: businessUserDetailData.mobile,
        business_name: businessUserDetailData.business_name,
        source: businessUserDetailData.source,
        business_website: businessUserDetailData.business_website,
        business_address: businessUserDetailData.business_address,
      })
    }
  }, [businessUserDetailData])



  const onFinish = (values) => {
    actionUpdateBusinessUser(values, Navigate, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.error({ errorInfo });
  };

  return (
    <Spin spinning={businessUserDetailLoader}>
      <Row gutter={[0, 24]}>
        <Col span={24}>

          <Card
            className="form-card-border"
            title={<h3>Update Business User</h3>}
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  initialValues={{ country_phone_code: "+91" }}
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={24} className="mb-5" style={{ marginBottom: 15 }}>
                      <Typography.Text className="text-[22px]  font-bold" style={{ fontWeight: "bold", fontSize: 22 }}>
                        Owner's Details
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="First Name"
                        name="first_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                          ({ getFieldValue }) => ({
                            validator: (_, value) => {
                              if (value && value.length >= 15) {
                                return Promise.reject(
                                  new Error(
                                    "Please enter maximum 15 characters for first Name"
                                  )
                                )
                              }
                              return Promise.resolve()
                            }
                          })
                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        colon={false}
                        name="last_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },

                          ({ getFieldValue }) => ({
                            validator: (_, value) => {
                              if (value && value.length >= 15) {
                                return Promise.reject(
                                  new Error(
                                    "Please enter maximum 15 characters for last Name"
                                  )
                                )
                              }
                              return Promise.resolve()
                            }
                          })

                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Email"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input disabled
                          placeholder="Enter Email"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Mobile Number"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (value && value.length !== 10) {
                          //       return Promise.reject(
                          //         new Error("Invalid Mobile Number!")
                          //       );
                          //     }
                          //     return Promise.resolve();

                          //   },
                          // }),
                        ]}
                      >
                        <Input disabled
                          // autoFocus="none"
                          type={"number"}
                          placeholder="Enter Mobile Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Source"
                        colon={false}
                        name="source"
                        rules={[
                          {
                            required: true,
                            message: "Please select Source!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Source"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Search Engine(Google,yahoo,etc.)",
                              label: "Search Engine(Google,yahoo,etc.)",
                            },
                            {
                              value: "Recommended by friend or colleague",
                              label: "Recommended by friend or colleague",
                            },
                            {
                              value: "Social media",
                              label: "Social media",
                            },
                            {
                              value: "Blog or publication",
                              label: "Blog or publication",
                            },
                            {
                              value: "Other",
                              label: "Other",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24} className="mb-5" style={{ margin: "15px 0" }}>
                      <Typography.Text className="text-[22px]  font-bold" style={{ fontWeight: "bold", fontSize: 22 }}>
                        Business Details
                      </Typography.Text>
                    </Col>

                    <Col xl={12} md={24} sm={24} xs={24}>
                      <Form.Item
                        label="Business Name"
                        name="business_name"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Business Name!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter Business Name" />
                      </Form.Item>
                    </Col>

                    <Col xl={12} md={24} sm={24} xs={24}>
                      <Form.Item
                        label="Business Website"
                        name="business_website"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              var re =
                                /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

                              if (value && !re.test(value)) {
                                return Promise.reject(
                                  "Please enter a valid Website Url! "
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input size="large" placeholder="Enter Business Website" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Business Address"
                        name="business_address"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Address!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter Business Address" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={updateBusinessUserLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row >
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    businessUserDetailData: state.business.businessUserDetailData,
    businessUserDetailLoader: state.business.businessUserDetailLoader,
    deleteBusinessUserLoader: state.business.deleteBusinessUserLoader,
    updateBusinessUserLoader: state.business.updateBusinessUserLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetBusinessUserDetail, actionUpdateBusinessUser,

})(EditBusinessRequest);
