import * as actionTypes from "../action";

const initialState = {
  businessLoader: false,
  businessData: [],
  businessCount: "",
  addBusinessLoader: false,
  addBusinessData: [],
  businessDetailData: {},
  deleteBusinessLoader: false,
  updateBusinessLoader: false,
  businessDetailLoader: false,
  // Business User
  businessUsersData: [],
  businessUsersLoader: false,
  addBusinessUserLoader: false,
  businessUsersCount: "",
  deleteBusinessUserLoader: false,
  businessUserDetailLoader: false,
  updateBusinessUserLoader: false,
  approveBusinessLoading: false,
  rejectBusinessLoading: false,
  businessUserDetailData: {},
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_BUSINESS_LOADING: {
      return { ...state, businessLoader: action.payload };
    }
    case actionTypes.TOTAL_BUSINESS_COUNT: {
      return { ...state, businessCount: action.payload };
    }
    case actionTypes.GET_ALL_BUSINESS_DATA: {
      return { ...state, businessData: action.payload };
    }
    case actionTypes.UPDATE_ACTIVE_DEACTIVE_BUSINESS_LOADING:
      return {
        ...state, businessData: state.businessData.map((data) =>
          data._id === action.payload.id
            ? {
              ...data,
              is_loading: action.payload.is_active
            }
            : data
        ),
      };

    case actionTypes.UPDATE_ACTIVE_DEACTIVE_BUSINESS:
      return {
        ...state,
        businessData: state.businessData.map((data) =>
          data._id === action.payload._id
            ? {
              ...data,
              is_active: action.payload.is_active,
            }
            : data
        ),
      };
    case actionTypes.ADD_BUSINESS_LOADING: {
      return { ...state, addBusinessLoader: action.payload };
    }
    case actionTypes.ADD_BUSINESS_DATA: {
      return { ...state, addBusinessData: action.payload };
    }

    case actionTypes.GET_BUSINESS_DETAIL_LOADING:
      return { ...state, businessDetailLoader: action.payload };

    case actionTypes.GET_BUSINESS_DETAIL_DATA:
      return {
        ...state,
        businessDetailData: action.payload,
      };
    case actionTypes.DELETE_BUSINESS_LOADING:
      return { ...state, deleteBusinessLoader: action.payload };

    case actionTypes.DELETE_BUSINESS_ID:
      return {
        ...state,
        businessData: [
          ...state.businessData.filter((data) => data._id !== action.payload),
        ],
      };
    case actionTypes.UPDATE_BUSINESS_LOADING:
      return { ...state, updateBusinessLoader: action.payload };


    // Business User Starts Here
    case actionTypes.GET_ALL_BUSINESS_USER_LOADING: {
      return { ...state, businessUsersLoader: action.payload };
    }
    case actionTypes.TOTAL_BUSINESS_USER_COUNT: {
      return { ...state, businessUsersCount: action.payload };
    }
    case actionTypes.GET_ALL_BUSINESS_USER_DATA: {
      return { ...state, businessUsersData: action.payload };
    }
    case actionTypes.ADD_BUSINESS_USER_LOADER: {
      return { ...state, addBusinessUserLoader: action.payload };

    }
    case actionTypes.GET_BUSINESS_USER_DETAIL_LOADING:
      return { ...state, businessUserDetailLoader: action.payload };

    case actionTypes.GET_BUSINESS_USER_DETAIL_DATA:
      return {
        ...state,
        businessUserDetailData: action.payload,
      };
    case actionTypes.DELETE_BUSINESS_USER_LOADER:
      return { ...state, deleteBusinessUserLoader: action.payload };

    case actionTypes.DELETE_BUSINESS_USER_DATA:
      return {
        ...state,
        businessUsersData: [
          ...state.businessUsersData.filter((data) => data._id !== action.payload),
        ],
      };
    case actionTypes.APPROVE_BUSINESS_ID:
      return {
        ...state,
        businessUsersData: state.businessUsersData.filter((data) => data._id !== action.payload),
      };
    case actionTypes.APPROVE_BUSINESS_LOADING:
      return { ...state, approveBusinessLoading: action.payload };

    case actionTypes.REJECT_BUSINESS_ID:
      return {
        ...state,
        businessUsersData: state.businessUsersData.map(data => data._id === action.payload._id ? action.payload : data),
      };
    case actionTypes.REJECT_BUSINESS_LOADING:
      return { ...state, rejectBusinessLoading: action.payload };

    case actionTypes.UPDATE_BUSINESS_USER_LOADER:
      return { ...state, updateBusinessUserLoader: action.payload };

    default:
      return state;
  }
};

export default businessReducer;
