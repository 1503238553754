import React, { Fragment, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import {
  Button,
  Card,
  Col,
  theme,
  Form,
  Upload,
  Image,
  Steps,
  Input,
  InputNumber,
  Typography,
  Modal,
  message,
  Row,
  AutoComplete,
  Select,
  Checkbox,
  Segmented,
  DatePicker,
  Spin,
  Tooltip,
} from "antd";
import FileIcon from "../../assets/img/icon/file.png";
import TextArea from "antd/es/input/TextArea";
import { BASE_URL_UPLOAD } from "../config/web-config";
import ImgCrop from "antd-img-crop";
import {
  actionUpdateProperty,
  actionGetCountry,
  actionGetCities,
  actionGetStates,
  actionGetPropertyDetail,
} from "../../store/actions/propertyAction";
import { actionGetAllUser } from "../../store/actions/userAction";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { PlusOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Text } = Typography;

const plainOptions = ["Apple", "Pear", "Orange"];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const beforeUpload = () => {
  return false;
};
const UpdateProperty = (props) => {
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    actionUpdateProperty,
    actionGetCountry,
    actionGetStates,
    actionGetCities,
    propertyDetailData,
    actionGetPropertyDetail,
    propertyDetailLoader,
    countryLoader,
    countryData,
    updatePropertyLoader,
    stateLoader,
    stateData,
    cityLoader,
    cityData,
    actionGetAllUser,
    usersData,
  } = props;

  const { propertyId } = useParams();
  const [userId, setUserId] = useState("");
  const [UserShow, setUserShow] = useState(false);
  const [disableState, setDisableState] = useState(true);
  const [disableCity, setDisableCity] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileVideoList, setFileVideoList] = useState([]);
  const [inputValue, setInputValue] = useState({
    rental_low: "",
    rental_rent: "",
    rental_high: "",
    estimated_low: "",
    estimated_estimate: "",
    estimated_high: "",
  });

  const [checkboxValues, setCheckboxValues] = useState({
    applinces: [],
    basement: [],
    floor_covering: [],
    rooms: [],
    indoor_features: [],
    building_amenities: [],
    architectural_style: [],
    exterior: [],
    outdoor_amenities: [],
    parking: [],
    roof: [],
    view: [],
    cooling_type: [],
    heating_type: [],
    parkingheating_fual: [],
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [boolean, setBoolean] = useState(false);
  const [sId, setSId] = useState();

  // console.log(checkedValue);

  // Website Validation
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };
  const websiteOptions = autoCompleteResult.map((website, index) => ({
    key: index,
    label: website,
    value: website,
  }));

  useEffect(() => {
    if (propertyDetailData && propertyDetailData.country) {
      actionGetStates(propertyDetailData.country_code);
    }
  }, [propertyDetailData])

  useEffect(() => {
    if (propertyId) {
      actionGetPropertyDetail(propertyId);
    }
  }, [propertyId])



  useEffect(() => {
    actionGetCountry()
    actionGetAllUser(0, 500, null, ['user', 'admin', 'agent'], true);
  }, []);

  const handleUserChange = (e, data) => {
    setUserShow(true);
    setUserId(data.value);
  }

  console.log({ propertyDetailData })


  // Get States APi Bases On  Selected Country
  const handleGetStates = (e, data) => {
    actionGetStates(data.isoCode);
    setBoolean(true);
    setDisableState(false);
  };


  const handleGetCity = (e, data) => {
    setSId(e);
    actionGetCities(data.countryCode, data.isoCode);
    setDisableCity(false);
  };

  // Array Of Country
  const country = [];
  countryData &&
    countryData.length > 0 &&
    countryData.map((data, index) => {
      country.push({
        ...data,
        key: index,
        label: data.name,
        value: data.isoCode,
      });
    });

  // Array Of States
  const state = [];
  stateData &&
    stateData.length &&
    stateData.map((data, index) => {
      state.push({
        ...data,
        key: index,
        label: data.name,
        value: data.isoCode,
      });
    });

  // Array Of City
  const city = [];
  cityData &&
    cityData.length &&
    cityData.map((data, index) => {
      city.push({
        ...data,
        key: index,
        value: data.name,
        label: data.name,
      });
    });

  useEffect(() => {
    if (propertyDetailData && propertyDetailData.rental_analysis) {
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        rental_high: propertyDetailData.rental_analysis.high,
        rental_low: propertyDetailData.rental_analysis.low,
        rental_rent: propertyDetailData.rental_analysis.rent,
      }));

    }
    if (propertyDetailData && propertyDetailData.estimated_value) {
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        estimated_high: propertyDetailData.estimated_value.high,
        estimated_low: propertyDetailData.estimated_value.low,
        estimated_estimate: propertyDetailData.estimated_value.estimated,
      }));

    }
    form.setFieldsValue({
      street_address: propertyDetailData.street_address,
      home_type: propertyDetailData.home_type,
      country: propertyDetailData ? propertyDetailData.country_code : "",
      state: propertyDetailData ? propertyDetailData.state : "",
      city: propertyDetailData ? propertyDetailData.city : "",

      zip_code: propertyDetailData.zip_code,
      source: propertyDetailData.source,
      asking_price: propertyDetailData.asking_price,
      unit_no: propertyDetailData.unit_no,
      beds: propertyDetailData.beds,
      baths: propertyDetailData.baths,
      finished_square_feet: propertyDetailData.finished_square_feet
        ? propertyDetailData.finished_square_feet
        : "",
      lot_size: propertyDetailData.lot_size ? propertyDetailData.lot_size : "",
      year_built: propertyDetailData.year_built
        ? dayjs(propertyDetailData.year_built)
        : undefined,
      status: propertyDetailData.status,
      estimated_taxes: propertyDetailData.estimated_taxes,
      estimated_mortgage: propertyDetailData.estimated_mortgage,
      structural_remodel_year: propertyDetailData.structural_remodel_year
        ? dayjs(propertyDetailData.structural_remodel_year)
        : undefined,
      hoa_dues: propertyDetailData.hoa_dues,
      basement_square_feet: propertyDetailData.basement_square_feet,
      garage_square_feet: propertyDetailData.garage_square_feet,
      description: propertyDetailData.description,
      other_source: propertyDetailData.other_source,
      parking_spaces: propertyDetailData.parking_spaces,
      foundation: propertyDetailData.foundation,
      fireplaces: propertyDetailData.fireplaces,
      constructions: propertyDetailData.constructions,
      more_info_link: propertyDetailData.more_info_link,
      describe_the_neighbourhood: propertyDetailData.describe_the_neighbourhood,
      related_website: propertyDetailData.related_website,
      user: propertyDetailData.user && propertyDetailData.user.first_name + " " + propertyDetailData.user.last_name
    });
    propertyDetailData &&
      setCheckboxValues({
        ...checkboxValues,
        applinces: propertyDetailData.applinces,
        basement: propertyDetailData.basement,
        floor_covering: propertyDetailData.floor_covering,
        rooms: propertyDetailData.rooms,
        indoor_features: propertyDetailData.indoor_features,
        building_amenities: propertyDetailData.building_amenities,
        architectural_style: propertyDetailData.architectural_style,
        exterior: propertyDetailData.exterior,
        outdoor_amenities: propertyDetailData.outdoor_amenities,
        parking: propertyDetailData.parking,
        roof: propertyDetailData.roof,
        cooling_type: propertyDetailData.cooling_type,
        view: propertyDetailData.view,
        heating_type: propertyDetailData.heating_type,
        parkingheating_fual: propertyDetailData.parkingheating_fual,
      });
    setPreviewImage(propertyDetailData.property_images);
    const ImagesArry = [];
    const VideoArry = [];
    propertyDetailData &&
      propertyDetailData.property_images &&
      propertyDetailData.property_images.map((data, index) => {
        if (data.endsWith("mp4")) {
          VideoArry.push({
            key: index,
            id: index,
            url: data,
            thumbUrl: data && data.replace('.mp4', '-thumb.png')
          });
        } else {
          ImagesArry.push({
            id: index,
            url: data,
          });
        }
      });

    ImagesArry && setFileList(ImagesArry);
    VideoArry && setFileVideoList(VideoArry);
  }, [propertyDetailData]);

  // Get Estimated Value And Rental Values
  const handleInputChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };




  // Steps Array in Steps
  // const applinces = [
  const applinces = [
    { label: "Dishwasher", value: "Dishwasher" },
    { label: "Range/oven", value: "Range/oven" },
    { label: "Garbage disposal", value: "Garbage disposal" },
    { label: "Dryer", value: "Dryer" },
    { label: "Washer", value: "Washer" },
    { label: "Refrigerator", value: "Refrigerator" },
    { label: "Microwave", value: "Microwave" },
    { label: "Freezer", value: "Freezer" },
    { label: "Trash Compactor", value: "Trash Compactor" },
  ];
  const basement = [
    { label: "Unfinished", value: "Unfinished" },
    { label: "Carpet", value: "Carpet" },
    { label: "Partially Finished", value: "Partially Finished" },
    { label: "None", value: "None" },
  ];

  const floor_covering = [
    { label: "Carpet", value: "Carpet" },
    { label: "Hardwood", value: "Hardwood" },
    { label: "Slate", value: "Slate" },
    { label: "Tile", value: "Tile" },
    { label: "Concrete", value: "Concrete" },
    { label: "Laminate", value: "Laminate" },
    { label: "Softwood", value: "Softwood" },
    { label: "Other", value: "Other" },
  ];
  const rooms = [
    { label: "Breakfast nook", value: "Breakfast Nook" },
    { label: "Office", value: "Office" },
    { label: "Dining Room", value: "Dining Room" },
    { label: "Pantry", value: "Pantry" },
    { label: "Family Room", value: "Family Room" },
    { label: "Recreation Room", value: "Recreation Room" },
    { label: "Laundry Room", value: "Laundry Room" },
    { label: "Workshop", value: "Workshop" },
    { label: "Library", value: "Library" },
    { label: "Solarium/Atrium", value: "Solarium/Atrium" },
    { label: "Master Bath", value: "Master Bath" },
    { label: "Sun Room", value: "Sun Room" },
    { label: "Mud Room", value: "Mud Room" },
    { label: "Walk-In Closet", value: "Walk-In Closet" },
  ];

  const indoor_features = [
    { label: "Attic", value: "Attic" },
    { label: "Cable Ready", value: "Cable Ready" },
    { label: "Security System", value: "Security System" },
    { label: "Ceiling Fans", value: "Ceiling Fans" },
    { label: "Skylights", value: "Skylights" },
    { label: "Storm Windows", value: "Storm Windows" },
    { label: "Vaulted Ceiling", value: "Vaulted Ceiling" },
    { label: "Fireplace", value: "Fireplace" },
    { label: "Wet Bar", value: "Wet Bar" },
    { label: "Intercom System", value: "Intercom System" },
    { label: "Wired", value: "Wired" },
    { label: "Jetted Tub", value: "Jetted Tub" },
  ];
  const building_amenities = [
    { label: "Assisted Living", value: "Assisted Living" },
    { label: "Gated Entry", value: "Gated Entry" },
    { label: "Basketball Court", value: "Basketball Court" },
    { label: "Nearby Transport", value: "Nearby Transport" },
    { label: "Controlled Access", value: "Controlled Access" },
    { label: "Disabled Access", value: "Disabled Access" },
    { label: "Sports Court", value: "Sports Court" },
    { label: "Doorman", value: "Doorman" },
    { label: "Storage", value: "Storage" },
    { label: "Elevator", value: "Elevator" },
    { label: "Tennis Court", value: "Tennis Court" },
    { label: "Fitness Center", value: "Fitness Center" },
  ];
  const architectural_style = [
    { label: "Bungalow", value: "Bungalow" },
    { label: "Modern", value: "Modern" },
    { label: "Cape Cod", value: "Cape Cod" },
    { label: "Colonial", value: "Colonial" },
    { label: "Ranch/Rambler", value: "Ranch/Rambler" },
    { label: "Contemporary", value: "Contemporary" },
    { label: "Craftsman", value: "Craftsman" },
    { label: "Spanish", value: "Spanish" },
    { label: "French", value: "French" },
    { label: "Split Level", value: "Split Level" },
    { label: "Georgian", value: "Georgian" },
    { label: "Tudor", value: "Tudor" },
    { label: "Loft", value: "Loft" },
    { label: "Other", value: "Other" },
  ];
  const exterior = [
    { label: "Brick", value: "Brick" },
    { label: "Stucco", value: "Stucco" },
    { label: "Cement Concrete", value: "Cement/Concrete" },
    { label: "Vinyl", value: "Vinyl" },
    { label: "Composition", value: "Composition" },
    { label: "Wood", value: "Wood" },
    { label: "Metal", value: "Metal" },
    { label: "Wood Products", value: "Wood Products" },
    { label: "Shingle", value: "Shingle" },
    { label: "Stone", value: "Stone" },
    { label: "Other", value: "Other" },
  ];

  const outdoor_amenities = [
    { label: "Balcony/Patio", value: "Balcony/Patio" },
    { label: "Lawn", value: "Lawn" },
    { label: "Barbecue Area", value: "Barbecue Area" },
    { label: "Pond", value: "Pond" },
    { label: "Deck", value: "Deck" },
    { label: "Pool", value: "Pool" },
    { label: "Dock", value: "Dock" },
    { label: "Porch", value: "Porch" },
    { label: "Fenced Yard", value: "Fenced Yard" },
    { label: "RV Parking", value: "RV Parking" },
    { label: "Garden", value: "Garden" },
    { label: "Sauna", value: "Sauna" },
    { label: "Greenhouse", value: "Greenhouse" },
    { label: "Sprinkler System", value: "Sprinkler System" },
    { label: "Hot Tub/Spa", value: "Hot Tub/Spa" },
    { label: "Waterfront", value: "Waterfront" },
  ];

  const parking = [
    { label: "Carport", value: "Carport" },
    { label: "Off-Street", value: "Off-Street" },
    { label: "Garage Attached", value: "Garage Attached" },
    { label: "On-Street", value: "On-Street" },
    { label: "Garage Detached", value: "Garage Detached" },
    { label: "None", value: "None" },
  ];

  const roof = [
    { label: "Asphalt", value: "Asphalt" },
    { label: "Shake/Shingle", value: "Shake/Shingle" },
    { label: "Built-Up", value: "Built-Up" },
    { label: "Slate", value: "Slate" },
    { label: "Composition", value: "Composition" },
    { label: "Tile", value: "Tile" },
    { label: "Metal", value: "Metal" },
    { label: "Other", value: "Other" },
  ];

  const view = [
    { label: "City", value: "City" },
    { label: "Territorial", value: "Territorial" },
    { label: "Mountain", value: "Mountain" },
    { label: "Water", value: "Water" },
    { label: "Park", value: "Park" },
    { label: "None", value: "None" },
  ];

  const cooling_type = [
    { label: "Central", value: "Central" },
    { label: "Evaporative", value: "Evaporative" },
    { label: "Geothermal", value: "Geothermal" },
    { label: "Refrigeration", value: "Refrigeration" },
    { label: "Solar", value: "Solar" },
    { label: "Wall", value: "Wall" },
    { label: "Other", value: "Other" },
    { label: "None", value: "None" },
  ];
  const heating_type = [
    { label: "Baseboard", value: "Baseboard" },
    { label: "Forced Air", value: "Forced Air" },
    { label: "Geothermal", value: "Geothermal" },
    { label: "Heat Pump", value: "Heat Pump" },
    { label: "Radiant", value: "Radiant" },
    { label: "Stove", value: "Stove" },
    { label: "Wall", value: "Wall" },
    { label: "Other", value: "Other" },
  ];
  const parkingheating_fual = [
    { label: "Coal", value: "Coal" },
    { label: "Electric", value: "Electric" },
    { label: "Gas", value: "Gas" },
    { label: "Oil", value: "Oil" },
    { label: "Propane/Butane", value: "Propane/Butane" },
    { label: "Other", value: "Other" },
    { label: "Solar", value: "Solar" },
    { label: "Wood/Pellet", value: "Wood/Pellet" },
    { label: "None", value: "None" },
  ];

  const onChange = (key, data) => {
    setCheckboxValues(prevState => ({
      ...prevState,
      [key]: data,
    }));
  };

  console.log({ checkboxValues });
  // Steps Array in Steps

  const steps = [
    {
      title: "Room Details",
      content: (
        <>
          <Row className="custom-checkbox">
            <Col span={4}>
              <Form.Item
                // name="applinces"
                label={<p style={{ fontWeight: 500 }}>APPLINCES</p>}
                className="text-justify"
              >
                <Checkbox.Group options={applinces} value={checkboxValues.applinces} onChange={(data) => onChange("applinces", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="basement"
                label={<p style={{ fontWeight: 500 }}>BASEMENT</p>}
                className="text-justify"
              >
                <Checkbox.Group options={basement} value={checkboxValues.basement}
                  onChange={(data) => onChange("basement", data)} />
              </Form.Item>
              <Form.Item
                // name="floor_covering"
                label={<p style={{ fontWeight: 500 }}>FLOOR COVERING</p>}
                className="text-justify"
              >
                <Checkbox.Group options={floor_covering} value={checkboxValues.floor_covering}
                  onChange={(data) => onChange("floor_covering", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="rooms"
                label={<p style={{ fontWeight: 500 }}>ROOMS</p>}
                className="text-justify"
              >
                <div style={{ textAlign: "left", marginTop: 5, marginLeft: 5 }}>
                  <Text>Total No. rooms</Text>
                </div>
                <Checkbox.Group options={rooms} value={checkboxValues.rooms}
                  onChange={(data) => onChange("rooms", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="indoor_features"
                label={<p style={{ fontWeight: 500 }}>INDOOR FEATURES</p>}
                className="text-justify"
              >
                <Checkbox.Group options={indoor_features} value={checkboxValues.indoor_features}
                  onChange={(data) => onChange("indoor_features", data)} />

              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Building Details",
      content: (
        <>
          <Row className="custom-checkbox">
            <Col span={4}>
              <Form.Item
                // name="building_amenities"
                label={<p style={{ fontWeight: 500 }}>BUILDING AMENITIES</p>}
                className="text-justify"
              >
                <Checkbox.Group options={building_amenities} value={checkboxValues.building_amenities}
                  onChange={(data) => onChange("building_amenities", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="architectural_style"
                label={<p style={{ fontWeight: 500 }}>ARCHITECTURAL STYLE</p>}
                className="text-justify"
              >
                <Checkbox.Group options={architectural_style} value={checkboxValues.architectural_style} onChange={(data) => onChange("architectural_style", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="exterior"
                label={<p style={{ fontWeight: 500 }}>EXTERIOR</p>}
                className="text-justify"
              >
                <Checkbox.Group options={exterior} value={checkboxValues.exterior} onChange={(data) => onChange("exterior", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="outdoor_amenities"
                label={<p style={{ fontWeight: 500 }}>OUTDOOR AMENITIES</p>}
                className="text-justify"
              >
                <div style={{ textAlign: "left", marginTop: 5, marginLeft: 5 }}>
                  <Text>No. of stories</Text>
                </div>
                <Checkbox.Group options={outdoor_amenities} value={checkboxValues.outdoor_amenities} onChange={(data) => onChange("outdoor_amenities", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="parking"
                label={<p style={{ fontWeight: 500 }}>PARKING</p>}
                className="text-justify"
              >
                <div style={{ textAlign: "left", marginTop: 5, marginLeft: 5 }}>
                  <Text>Parking Spaces</Text>
                </div>
                <Checkbox.Group options={parking} value={checkboxValues.parking} onChange={(data) => onChange("parking", data)} />

              </Form.Item>
              <Form.Item
                // name="roof"
                label={<p style={{ fontWeight: 500 }}>ROOF</p>}
                className="text-justify"
              >
                <Checkbox.Group options={roof} value={checkboxValues.roof} onChange={(data) => onChange("roof", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="view"
                label={<p style={{ fontWeight: 500 }}>VIEW</p>}
                className="text-justify"
              >
                <Checkbox.Group options={view} value={checkboxValues.view} onChange={(data) => onChange("view", data)} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Utility Details",
      content: (
        <>
          <Row className="custom-checkbox">
            <Col span={4}>
              <Form.Item
                // name="cooling_type"
                label={<p style={{ fontWeight: 500 }}>COOLING TYPE</p>}
                className="text-justify"
              >
                <Checkbox.Group options={cooling_type} value={checkboxValues.cooling_type} onChange={(data) => onChange("cooling_type", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="heating_type"
                label={<p style={{ fontWeight: 500 }}>HEATING TYPE</p>}
                className="text-justify"
              >
                <Checkbox.Group options={heating_type} value={checkboxValues.heating_type} onChange={(data) => onChange("heating_type", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="parkingheating_fual"
                label={<p style={{ fontWeight: 500 }}>HEATING FUEL</p>}
                className="text-justify"
              >
                <Checkbox.Group options={parkingheating_fual} value={checkboxValues.parkingheating_fual} onChange={(data) => onChange("parkingheating_fual", data)} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  // Close The Modal Of preview Image
  const handleCancel = () => setPreviewOpen(false);

  // Preview Image OnClick

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    const updatedFileList = newFileList.map((file) => {
      if (file.type && file.type.startsWith("video/")) {
        // Set thumbnail image for video files
        // file.thumbUrl = file.response.file.path;

        file.thumbUrl =
          file.response &&
          file.response.file.path.substring(
            0,
            file.response.file.path.lastIndexOf(".")
          ) + "-thumb.png";
      }
      return file;
    });

    setFileList(newFileList);
  };

  // onchange event video

  const handleChangeVideo = ({ fileList: newFileVideoList }) => {
    let videoFileList =
      newFileVideoList &&
      newFileVideoList.map((file) => {
        if (file) {
          // Check if the 'file.type' property is defined before using 'startsWith'
          if (file.type && file.type.startsWith("video/")) {
            // Set thumbnail image for video files
            file.thumbUrl =
              file.response &&
              file.response.file.path.substring(
                0,
                file.response.file.path.lastIndexOf(".")
              ) + "-thumb.png";
          }
        }
        return file;
      });

    const videoSize =
      videoFileList &&
      videoFileList.some(
        (file) =>
          file.type && file.type.startsWith("video/") && file.size / 1024 / 1024 > 100
      );

    if (videoSize) {
      videoFileList = videoFileList.filter(
        (file) =>
          !file.type || !file.type.startsWith("video/") || file.size / 1024 / 1024 <= 100
      );
      message.error("Maximum video size allowed is 100MB!", 2);
    }

    setFileVideoList(videoFileList);
  };

  // console.log({});

  // Upload text Or Button

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // console.log(stateData, "stateData");

  // Submit the Form And Call The Api
  const onFinish = (values) => {

    const id = usersData && usersData.find(user => user.first_name + " " + user.last_name === values.user);

    const newId = id ? id._id : null;


    const imagesArr = [];
    const videoArr = [];
    const {
      asking_price,
      hoa_dues,
      year_built,
      structural_remodel_year,
    } = values;


    fileList?.forEach((d) => {
      if (d.response) {
        imagesArr.push(d.response.file.filename);
      } else if (d.is_cropper_image) {
        imagesArr.push(d.is_cropper_image);
      } else if (d.url) {
        const parts = d.url.split("/");
        const value = parts[parts.length - 1];
        imagesArr.push(value);
      } else {
        imagesArr.push(d.filename);
      }
    });

    fileVideoList &&
      fileVideoList.length > 0 &&
      fileVideoList.map((d) => {
        // console.log(d, 'd');
        if (d.response) {
          videoArr.push(d.response.file.filename);
        } else {
          const parts = d.url.split("/");
          const value = parts[parts.length - 1];
          videoArr.push(value)
        };
      });


    const stateCode = state.find((d) => d.name === values.state);

    const s = stateCode ? stateCode.isoCode : sId;
    const request = {
      ...values,
      state: s,
      applinces:
        checkboxValues && checkboxValues.applinces ? checkboxValues.applinces : [],
      basement: checkboxValues?.basement || [],
      rooms: checkboxValues?.rooms || [],
      floor_covering: checkboxValues?.floor_covering || [],
      indoor_features: checkboxValues?.indoor_features || [],
      building_amenities: checkboxValues?.building_amenities || [],
      architectural_style: checkboxValues?.architectural_style || [],
      exterior: checkboxValues?.exterior || [],
      outdoor_amenities: checkboxValues?.outdoor_amenities || [],
      parking: checkboxValues?.parking || [],
      roof: checkboxValues?.roof || [],
      view: checkboxValues?.view || [],
      cooling_type: checkboxValues?.cooling_type || [],
      heating_type: checkboxValues?.heating_type || [],
      parkingheating_fual: checkboxValues?.parkingheating_fual || [],
      estimated_value: {
        low: inputValue?.estimated_low || 0,
        estimated: inputValue?.estimated_estimate || 0,
        high: inputValue?.estimated_high || 0,
      },
      rental_analysis: {
        low: inputValue?.rental_low || 0,
        rent: inputValue?.rental_rent || 0,
        high: inputValue?.rental_high || 0,
      },
      property_images: imagesArr.concat(videoArr),

      asking_price: parseInt(asking_price),
      hoa_dues: parseInt(hoa_dues),
      finished_square_feet: values.finished_square_feet?.toString() || "",
      lot_size: values.lot_size?.toString() || "",
      year_built: year_built ? dayjs(year_built).format("YYYY") : "",
      user: UserShow ? userId : newId,
      structural_remodel_year: structural_remodel_year
        ? dayjs(structural_remodel_year).format("YYYY")
        : "",
    };

    console.log("Success:", request);
    actionUpdateProperty(request, Navigate, propertyId);
  };

  // Error Show if Form values blank or error in!
  const onFinishFailed = (errorInfo) => {
    // messageApi.error("Please Fill");
    const layout = document.querySelectorAll(".ant-layout-content");

    layout.forEach((element) => {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  useEffect(() => {
    const layout = document.querySelectorAll(".ant-layout-content");
    layout.forEach((element) => {
      element.scrollTo({
        top: 0,
      });
    });
  }, [])

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item, index) => ({
    key: index,
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    textAlign: "center",
    color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    lineHeight: 5,
    borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };


  const users = [];
  usersData && usersData.map((user, index) => {
    if (user.roles !== "user") {
      users.push({
        key: index,
        label: user.first_name + " " + user.last_name,
        value: user._id,
      });
    }
  })

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // console.log({ sId });

  return (
    <Fragment>
      {contextHolder}

      <Spin spinning={propertyDetailLoader}>
        <Row gutter={[0, 24]} id="frag">
          <Col span={24}>
            <Card
              className="form-card-border"
              title={<h3>Update Property</h3>}
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form
                    form={form}
                    autoComplete="off"
                    requiredMark={true}
                    initialValues={
                      {
                        // related_website: propertyDetailData.related_website,
                      }
                    }
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <Form.Item
                          label="Select User"
                          colon={false}
                          name="user"
                          rules={[
                            {
                              required: true,
                              message: "Please Select User!",
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            placeholder="Select User"
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            onChange={handleUserChange}
                            filterOption={filterOption}
                            options={users}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Street address"
                          name="street_address"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Street address!",
                            },
                          ]}
                        >
                          <TextArea
                            placeholder="Enter Street address"
                            rows={1}
                            autoFocus="none"
                            type={"text"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Home type"
                          name="home_type"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Home type!",
                            },
                          ]}
                        >
                          <Select
                            // defaultValue="Single Family"
                            showSearch
                            placeholder="Select a Home Type"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              {
                                value: "Single Family",
                                label: "Single Family",
                                selected: true,
                              },
                              { value: "Condo", label: "Condo" },
                              { value: "Townhomes", label: "Townhomes" },
                              { value: "Apartment", label: "Apartment" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Country"
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Country!",
                            },
                          ]}
                        >
                          <Select
                            loading={countryLoader}
                            onSelect={handleGetStates}
                            onChange={() => form.setFieldsValue({
                              state: null,
                              city: null,
                            })}
                            allowClear
                            showSearch
                            placeholder="Select Country"
                            optionFilterProp="children"
                            filterOption={(iv, op) =>
                              op.label
                                .toLocaleLowerCase()
                                .includes((iv || "").toLocaleLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={country}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="State"
                          disabled={disableState}
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "Please input your State!",
                            },
                          ]}
                        >
                          {/* <Input autoFocus="none" type={"text"} /> */}
                          <Select
                            loading={stateLoader}
                            onSelect={handleGetCity}
                            disabled={disableState ? true : false}
                            showSearch
                            placeholder="Select State"
                            optionFilterProp="children"
                            filterOption={(iv, op) =>
                              op.label
                                .toLocaleLowerCase()
                                .includes((iv || "").toLocaleLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={state}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="City"
                          name="city"
                          // normalize={(value) =>
                          //   value.replace(/[^a-zA-Z]/g, "").trim()
                          // }
                          rules={[
                            {
                              required: true,
                              message: "Please input your City!",
                            },
                          ]}
                        >
                          <Select
                            loading={cityLoader}
                            disabled={disableCity ? true : false}
                            showSearch
                            placeholder="Select City"
                            optionFilterProp="children"
                            filterOption={(iv, op) =>
                              op.label
                                .toLocaleLowerCase()
                                .includes((iv || "").toLocaleLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={city}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Zip Code"
                          colon={false}
                          name="zip_code"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Zip Code!",
                            },
                            {
                              validator: (_, value) =>
                                value && value.length === 5
                                  ? Promise.resolve()
                                  : Promise.reject(
                                    new Error(
                                      "Zip Code cannot exceed 5 characters!"
                                    )
                                  ),
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Zip Code"
                            autoFocus="none"
                            type={"number"}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Source"
                          colon={false}
                          name="source"
                          rules={[
                            {
                              required: true,
                              message: "Please select Source.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select a Sorce"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              {
                                value: "visit",
                                label: "visit",
                              },
                              {
                                value: "Zillow",
                                label: "Zillow",
                              },
                              {
                                value: "Realtor",
                                label: "Realtor",
                              },
                              {
                                value: "LoopNet",
                                label: "LoopNet",
                              },
                              {
                                value: "Other",
                                label: "Other",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Asking Price"
                          colon={false}
                          name="asking_price"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Asking Price!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Asking Price"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Unit No"
                          colon={false}
                          name="unit_no"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Unit No!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Unit No"
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                        <Form.Item
                          label="Beds"
                          colon={false}
                          name="beds"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Beds!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Beds"
                          />
                        </Form.Item>
                      </Col> */}
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Beds"
                          name="beds"
                          rules={[
                            {
                              required: true,
                              message: "Please input beds*!",
                            },
                          ]}
                        >
                          <Select
                            // defaultValue="Single Family"
                            showSearch
                            placeholder="Select a Beds"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                              { value: "4", label: "4" },
                              { value: "5+", label: "5+" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                        <Form.Item
                          label="Baths"
                          colon={false}
                          name="baths"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Baths!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Bath"
                          />
                        </Form.Item>
                      </Col> */}
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Baths"
                          name="baths"
                          rules={[
                            {
                              required: true,
                              message: "Please input baths*!",
                            },
                          ]}
                        >
                          <Select
                            // defaultValue="Single Family"
                            showSearch
                            placeholder="Select a Bath"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              { value: "1", label: "1" },
                              { value: "1.5", label: "1.5" },
                              { value: "2", label: "2" },
                              { value: "2.5", label: "2.5" },
                              { value: "3+", label: "3+" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Finished square feet"
                          colon={false}
                          name="finished_square_feet"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Finished square feet!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Finished Square Feet"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Lot size"
                          colon={false}
                          name="lot_size"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Lot size!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Lot Size"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Year built"
                          colon={false}
                          name="year_built"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Year built!",
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onChange}
                            picker="year"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Status"
                          name="status"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Status!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select a Status"
                            // defaultValue={"For Sale"}

                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              { value: "For Sale", label: "For Sale" },
                              { value: "For Rent", label: "For Rent" },
                              { value: "Sold", label: "Sold" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Estimated Taxes"
                          colon={false}
                          name="estimated_taxes"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Year Estimated Taxes!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Estimated Tax"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Estimated Value"
                          colon={false}
                          // name="estimated_value"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Year Estimated Value!",
                            },
                          ]}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={8}>
                              <Input
                                name="estimated_low"
                                onChange={handleInputChange}
                                autoFocus="none"
                                value={
                                  inputValue.estimated_low
                                }
                                type={"number"}
                                placeholder="Low"
                              />
                            </Col>
                            <Col span={8}>
                              <Input
                                name="estimated_estimate"
                                onChange={handleInputChange}
                                autoFocus="none"
                                value={
                                  inputValue.estimated_estimate
                                }
                                type={"number"}
                                placeholder="Estimated"
                              />
                            </Col>
                            <Col span={8}>
                              <Input
                                name="estimated_high"
                                onChange={handleInputChange}
                                value={
                                  inputValue.estimated_high
                                }
                                autoFocus="none"
                                type={"number"}
                                placeholder="High"
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Rental Analysis"
                          colon={false}
                          name="rental_ayalysis"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Year Estimated Analysis!",
                            },
                          ]}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={8}>
                              <Input
                                name="rental_low"
                                onChange={handleInputChange}
                                value={
                                  inputValue.rental_low
                                }
                                autoFocus="none"
                                type={"number"}
                                placeholder="Low"
                              />
                            </Col>
                            <Col span={8}>
                              <Input
                                autoFocus="none"
                                onChange={handleInputChange}
                                name="rental_rent"
                                value={
                                  // propertyDetailData?.rental_analysis?.rent || inputValue
                                  inputValue.rental_rent
                                }
                                type={"number"}
                                placeholder="Rent"
                              />
                            </Col>
                            <Col span={8}>
                              <Input
                                autoFocus="none"
                                onChange={handleInputChange}
                                name="rental_high"
                                value={
                                  inputValue.rental_high
                                }
                                type={"number"}
                                placeholder="High"
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Estimated Mortgage"
                          colon={false}
                          name="estimated_mortgage"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Estimated Mortgage!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Estimated Mortgage"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Structural remodel year"
                          colon={false}
                          name="structural_remodel_year"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Structural remodel year!",
                            },
                          ]}
                        >
                          <DatePicker picker="year" style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="HOA dues"
                          colon={false}
                          name="hoa_dues"
                          rules={[
                            {
                              required: false,
                              message: "Please enter HOA dues!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Hoa Fees"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Basement sq. ft."
                          colon={false}
                          name="basement_square_feet"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Basement sq. ft.!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Enter Basement sqft"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Garage sq. ft."
                          colon={false}
                          name="garage_square_feet"
                          // normalize={(value) =>
                          //   value.replace(/[^a-zA-Z]/g, "").trim()
                          // }
                          rules={[
                            {
                              required: false,
                              message: "Please enter Garage sqft!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Garage sqft"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Describe the Property"
                          colon={false}
                          name="description"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Describe the Property!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Describe the Property"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Other Source"
                          colon={false}
                          name="other_source"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Other Source!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Other Source"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Parking Space"
                          colon={false}
                          name="parking_spaces"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Parking Space!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Parking Space "
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Foundation"
                          colon={false}
                          name="foundation"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Foundation!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Foundation"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Fireplaces"
                          colon={false}
                          name="fireplaces"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Fireplaces!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Fireplaces"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Constructions"
                          colon={false}
                          name="constructions"
                          rules={[
                            {
                              required: false,
                              message: "Please enter Constructions!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter Constructions"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="More Info Link"
                          colon={false}
                          name="more_info_link"
                          rules={[
                            {
                              required: false,
                              message: "Please enter More Info Link!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Enter More Info Link "
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Describe the neighbourhood"
                          colon={false}
                          name="describe_the_neighbourhood"
                          rules={[
                            {
                              required: false,
                              message:
                                "Please enter Describe the neighbourhood!",
                            },
                          ]}
                        >
                          <TextArea
                            rows={3}
                            placeholder="Describe Neighbourhood"
                            autoFocus="none"
                            type={"text"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          style={{ marginTop: "30px" }}
                          colon={false}
                          // name="Describe the neighbourhood"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: false,
                              message:
                                "Please enter Describe the neighbourhood!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            value={"Additional Information "}
                            style={{
                              color: "#443527",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "21px",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="related_website"
                          label="Related Website"
                          rules={[
                            {
                              required: false,
                              message: "Please input related_website!",
                            },
                          ]}
                        >
                          <AutoComplete
                            options={websiteOptions}
                            onChange={onWebsiteChange}
                            placeholder="Related Website"
                            allowClear
                          >
                            <Input />
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                      <Col span={20} style={{ margin: "auto" }}>
                        <Steps
                          current={current}
                          percent={60}
                          labelPlacement="vertical"
                          items={items}
                        />
                        <div style={contentStyle}>{steps[current].content}</div>
                        <div
                          style={{
                            marginTop: 24,
                          }}
                        >
                          {current > 0 && (
                            <Button
                              style={{
                                margin: "0 8px",
                              }}
                              onClick={() => prev()}
                            >
                              Previous
                            </Button>
                          )}
                          {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                              Next
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col span={12} className="main-button-text">
                        <br />
                        <Form.Item
                          label="Property Images"
                          rules={[
                            {
                              required: false,
                              message: "Please Upload Property Image!",
                            },
                          ]}
                        >
                          <div>
                            <Upload
                              accept="image/*"
                              status={fileList}
                              id="img-cropper"
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("scoutheimAdminToken"),
                              }}
                              action={BASE_URL_UPLOAD}
                              listType="picture-card"
                              fileList={fileList}
                              onPreview={handlePreview}
                              onChange={handleChange}
                            >
                              {uploadButton}
                            </Upload>
                            {/* </ImgCrop> */}
                            <Modal
                              open={previewOpen}
                              title={previewTitle}
                              footer={null}
                              onCancel={handleCancel}
                            >
                              <Image
                                alt="example"
                                style={{
                                  width: "100%",
                                }}
                                preview={false}
                                src={previewImage}
                              />
                            </Modal>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12} className="main-button-text">
                        <br />
                        <Form.Item
                          label="Property Video"
                          rules={[
                            {
                              required: true,
                              message: "Please Upload Property Video!",
                            },
                          ]}
                        >
                          <div>
                            <Upload
                              // multiple
                              name="file"
                              accept="video/*"
                              // id="img-cropper"
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("scoutheimAdminToken"),
                              }}
                              action={BASE_URL_UPLOAD}
                              listType="picture-card"
                              fileList={fileVideoList}
                              // onPreview={handlePreview}
                              onChange={handleChangeVideo}
                            // beforeUpload={beforeUpload}
                            >
                              {uploadButton}
                            </Upload>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ marginTop: 30 }}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={updatePropertyLoader}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryLoader: state.properties.countryLoader,
    countryData: state.properties.countryData,
    updatePropertyLoader: state.properties.updatePropertyLoader,
    stateLoader: state.properties.stateLoader,
    stateData: state.properties.stateData,
    cityLoader: state.properties.cityLoader,
    cityData: state.properties.cityData,
    propertyDetailData: state.properties.propertyDetailData,
    propertyDetailLoader: state.properties.propertyDetailLoader,
    usersData: state.user.usersData,
  };
};
export default connect(mapStateToProps, {
  actionUpdateProperty,
  actionGetCountry,
  actionGetCities,
  actionGetStates,
  actionGetPropertyDetail,
  actionGetAllUser,
})(UpdateProperty);
