import { Col, Row, Image, Card, Form, Input, TextArea, Button, Select, Spin, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import userImage from '../../assets/img/dashboard/user-card.png'
import dealsImage from '../../assets/img/dashboard/deals.png'
import propertyImage from '../../assets/img/dashboard/properties.png'
import { connect } from "react-redux";
import { Column } from '@ant-design/plots';
import { Pie } from '@ant-design/plots';
import { actionGetDashboardData, actionGetDealDashboardData } from "../../store/actions/propertyAction";
import { actionGetAllUser } from '../../store/actions/userAction';
import { actionSendNotification } from '../../store/actions/notificationAction';

const Dashboard = (props) => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [value, setValue] = useState();

    const {
        actionGetDashboardData,
        dashboardData, actionGetDealDashboardData,
        actionGetAllUser,
        usersData, dealsDashboardData,
        dealsDashboardLoader,
        usersLoader, userProfileData,
        dashboardLoader,
        actionSendNotification,
        sendNotificationLoader,
        sendNotificationData
    } = props;

    useEffect(() => {
        if (userProfileData && userProfileData.roles === 'admin') {
            actionGetDashboardData();
            actionGetAllUser(0, 500, null, ['user', 'admin', 'agent'], true);
        } else {
            actionGetDealDashboardData();

        }
    }, []);

    const handleUserSearch = (value) => {
        actionGetAllUser(0, 20, value);
    }


    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const data = [];

    dashboardData && dashboardData.yearly_report && dashboardData.yearly_report.length > 0 &&
        dashboardData.yearly_report.map((d, i) => {
            data.push({
                key: i,
                name: d.name,
                month: d.month,
                count: d.value
            })
        })

    const config = {
        data: data,
        color: ["#FF9A3E", "#443527"],
        isGroup: true,
        xField: 'month',
        yField: 'count',
        seriesField: 'name',
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };
    const dataPie = [
        {
            type: 'Properties',
            value: 30,
        },
        {
            type: 'Open House',
            value: 80,
        }
    ];

    const configPie = {
        appendPadding: 10,
        data: dataPie,
        color: ["#FF9A3E", "#443527"],
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };

    const users = [];
    const agents = [];
    const admins = [];

    usersData && usersData.map((user, index) => {
        const userObject = {
            // key: index,
            key: user._id,
            label: user.first_name + " " + user.last_name,
            value: user._id,
        };

        switch (user.roles) {
            case 'user':
                users.push(userObject);
                break;
            case 'agent':
                agents.push(userObject);
                break;
            case 'admin':
                admins.push(userObject);
                break;
            default:
                break;
        }
    });

    const treeData = [
        {
            value: 'all',
            title: 'All',
            children: [
                {
                    value: 'users',
                    title: 'Users',
                    children: users,
                },
                {
                    value: 'agents',
                    title: 'Agents',
                    children: agents,
                },
                {
                    value: 'admins',
                    title: 'Admins',
                    children: admins,

                },
            ],
        },
    ];

    const onChange = (newValue) => {
        setValue(newValue);
    };
    const onFinish = (values) => {
        const request = {
            ...values,
            notificationtype: "Simple Message",
        };
        actionSendNotification(request);
        form.resetFields();
    };

    const filterTreeNode = (inputValue, treeNode) => {
        const nodeTitle = treeNode.label?.toLowerCase();
        return nodeTitle && nodeTitle.includes(inputValue.toLowerCase());
    };

    /*  const businessData = [
         {
             name: 'London',
             month: 'Jan.',
             count: 18.9,
         },
         {
             name: 'London',
             month: 'Feb.',
             count: 28.8,
         },
         {
             name: 'London',
             month: 'Mar.',
             count: 39.3,
         },
         {
             name: 'London',
             month: 'Apr.',
             count: 81.4,
         },
         {
             name: 'London',
             month: 'May',
             count: 47,
         },
         {
             name: 'London',
             month: 'Jun.',
             count: 20.3,
         },
         {
             name: 'London',
             month: 'Jul.',
             count: 24,
         },
         {
             name: 'London',
             month: 'Aug.',
             count: 35.6,
         },
         {
             name: 'Berlin',
             month: 'Jan.',
             count: 12.4,
         },
         {
             name: 'Berlin',
             month: 'Feb.',
             count: 23.2,
         },
         {
             name: 'Berlin',
             month: 'Mar.',
             count: 34.5,
         },
         {
             name: 'Berlin',
             month: 'Apr.',
             count: 99.7,
         },
         {
             name: 'Berlin',
             month: 'May',
             count: 52.6,
         },
         {
             name: 'Berlin',
             month: 'Jun.',
             count: 35.5,
         },
         {
             name: 'Berlin',
             month: 'Jul.',
             count: 37.4,
         },
         {
             name: 'Berlin',
             month: 'Aug.',
             count: 42.4,
         },
     ]; */

    const businessData = []
    
    dealsDashboardData && dealsDashboardData.length && dealsDashboardData.map((data) => {
        businessData.push({
            name: data.status,
            month: data.month,
            count: data.count
        })
    })


    const businessConfig = {
        data: businessData,
        isGroup: true, color: ["#FF9A3E", "#443527"],
        xField: 'month',
        yField: 'count',
        seriesField: 'name',
        label: {
            position: 'middle',
            // 'top', 'middle', 'bottom'
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };

    let activeCount = 0;
    let expiredCount = 0;

    dealsDashboardData && dealsDashboardData.length > 0 && dealsDashboardData.forEach(record => {
        if (record.status === "Active") {
            activeCount += record.count;
        } else if (record.status === "Expired") {
            expiredCount += record.count;
        }
    });


    return (
        <Spin spinning={dashboardLoader}>
            <Row className='main-card-wrapper' gutter={16} >
                {userProfileData && userProfileData.roles === 'admin' ?
                    dashboardData && dashboardData.main_hearde &&
                    <>
                        <Col span={6}>
                            <div className="card-blue">
                                <Row>
                                    <Col span={18} className="text-card" >
                                        <p className='font-title'>Users</p>
                                        <p className='font-text'>{dashboardData.main_hearde.users ? dashboardData.main_hearde.users : 0}</p>
                                    </Col>
                                    <Col span={6} className="image-card-blue" >
                                        <Image src={userImage} preview={false} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="card-yellow" >
                                <Row>
                                    <Col span={18} className="text-card" >
                                        <p className='font-title'>Agents</p>
                                        <p className='font-text'>{dashboardData.main_hearde.agents ? dashboardData.main_hearde.agents : 0}</p>
                                    </Col>
                                    <Col span={6} className="image-card-yellow" >
                                        <Image src={userImage} preview={false} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="card-pink"><Row>
                                <Col span={18} className="text-card" >
                                    <p className='font-title'>Deals of the Day</p>
                                    <p className='font-text'>{dashboardData.main_hearde.dod ? dashboardData.main_hearde.dod : 0}</p>
                                </Col>
                                <Col span={6} className="image-card-pink" >
                                    <Image src={dealsImage} preview={false} />
                                </Col>
                            </Row></div>
                        </Col>
                        <Col span={6}>
                            <div className="card-light-blue"  ><Row>
                                <Col span={18} className="text-card" >
                                    <p className='font-title'>Properties</p>
                                    <p className='font-text'>
                                        {dashboardData.main_hearde.properties ? dashboardData.main_hearde.properties : 0}
                                    </p>
                                </Col>
                                <Col span={6} className="image-card-light-blue" >
                                    <Image src={propertyImage} preview={false} />
                                </Col>
                            </Row></div>
                        </Col>
                    </> :
                    <>
                        <Col span={8}>
                            <div className="card-blue">
                                <Row>
                                    <Col span={18} className="text-card" >
                                        <p className='font-title'>Total Deals</p>
                                        <p className='font-text'>{activeCount + expiredCount}</p>
                                    </Col>
                                    <Col span={6} className="image-card-blue" >
                                        <Image src={dealsImage} preview={false} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="card-yellow" >
                                <Row>
                                    <Col span={18} className="text-card" >
                                        <p className='font-title'>Total Active Deals</p>
                                        <p className='font-text'>{activeCount}</p>
                                    </Col>
                                    <Col span={6} className="image-card-yellow" >
                                        <Image src={dealsImage} preview={false} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="card-pink"><Row>
                                <Col span={18} className="text-card" >
                                    <p className='font-title'>Total Expired Deals</p>
                                    <p className='font-text'>{expiredCount}</p>
                                </Col>
                                <Col span={6} className="image-card-pink" >
                                    <Image src={dealsImage} preview={false} />
                                </Col>
                            </Row></div>
                        </Col></>
                }


            </Row>
            <Row style={{ marginTop: '20px' }} gutter={[16, 16]}>
                <>
                    <Col span={userProfileData && userProfileData.roles === "admin" ? 16 : 24}>
                        <Card
                            className="form-card-border list-layout-li"
                            // align="middle"
                            title={`${userProfileData && userProfileData.roles === "admin" ? "Monthly Property/added open house" : "Monthly Total Deals/Expired Deals"}`}
                            bordered={false}
                            style={{ width: "100%" }}
                        >
                            <Column {...(userProfileData && userProfileData.roles === "admin" ? config : businessConfig)} />
                        </Card>
                    </Col>
                    {userProfileData && userProfileData.roles === "admin" && <Col span={8}>
                        <Card
                            className="form-card-border list-layout-li"
                            align="middle"
                            title="Property/added open house "
                            bordered={false}
                            style={{ width: "100%" }}
                        >

                            {configPie && <Pie {...configPie} />}
                        </Card>
                    </Col>}
                </>

                {
                    userProfileData && userProfileData.roles === "admin" &&
                    <Col span={16}>
                        <Card
                            className="form-card-border list-layout-li"
                            // align="middle"
                            title="Send Notification"
                            bordered={false}
                            style={{ width: "100%" }}
                        >
                            <Form
                                autoComplete="off"
                                requiredMark={true}
                                form={form}
                                layout="vertical"
                                size="large"
                                onFinish={onFinish}
                            //   onFinishFailed={onFinishFailed}
                            >
                                <Row gutter={[16]}>

                                    <Col span={20}>
                                        <Form.Item
                                            label="Title"
                                            colon={false}
                                            name="title"
                                            // normalize={(value) =>
                                            //     value.replace(/[^a-zA-Z]/g, "").trim()
                                            // }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter title!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                // autoFocus="none"
                                                type={"text"}
                                            //   placeholder="Enter Title"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            label="Message..."
                                            colon={false}
                                            name="description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter message!",
                                                },
                                            ]}
                                        >
                                            {/* <Input.Password placeholder="Enter Business Address" /> */}
                                            <TextArea
                                                style={{
                                                    height: 100,
                                                    resize: "none",
                                                }}
                                                //   placeholder="Business Address"
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            label="Select Users"
                                            colon={false}
                                            name="users"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Select Users!",
                                                },
                                            ]}
                                        >
                                            {/* <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: "100%",
                                            }}
                                            loading={usersLoader}
                                            disabled={usersLoader}
                                            // onSearch={handleUserSearch}
                                            filterOption={filterOption}
                                            options={users}
                                        /> */}

                                            <TreeSelect
                                                className="custom-tree-select"
                                                multiple
                                                treeCheckable
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={value}
                                                dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                }}
                                                // placeholder="Select Users"
                                                allowClear
                                                // treeDefaultExpandAll
                                                treeDefaultExpandedKeys={["all"]}
                                                onChange={onChange}
                                                treeData={treeData}
                                                filterTreeNode={filterTreeNode}
                                            />

                                        </Form.Item>

                                    </Col>
                                    <Col span={24}>
                                        <Form.Item normalize={(value) => value.trim()}>
                                            <Button
                                                loading={sendNotificationLoader}
                                                align="center"
                                                htmlType="submit"
                                                className="primary"
                                                size="large"
                                                style={{ width: 200, marginTop: "30px" }}
                                            >
                                                Send
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                }
            </Row>
        </Spin >
    );
}

const mapStateToProps = (state) => {
    return {
        dashboardLoader: state.properties.dashboardLoader,
        dashboardData: state.properties.dashboardData,
        dealsDashboardData: state.properties.dealsDashboardData,
        dealsDashboardLoader: state.properties.dealsDashboardLoader,
        usersData: state.user.usersData,
        userProfileData: state.auth.userProfileData,
        usersLoader: state.user.usersLoader,
        sendNotificationData: state.notification.sendNotificationData,
        sendNotificationLoader: state.notification.sendNotificationLoader,
    };
};
export default connect(mapStateToProps, {
    actionGetDashboardData,
    actionGetAllUser, actionGetDealDashboardData,
    actionSendNotification
})(Dashboard);
