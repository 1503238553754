import React, { useEffect, useMemo } from "react";
import {
  Card,
  Image,
  Typography,
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
} from "antd";
import logo from "../../assets/img/main-wrapper/logo.png";
import { connect } from "react-redux";
import { actionForgotPassword } from "../../store/actions/authAction";
import { useNavigate, useLocation } from "react-router-dom";
const { Title } = Typography;

const ForgotPassword = (props) => {
  const Navigate = useNavigate();
  const { actionForgotPassword, forgotPasswordLoader } = props;
  const [form] = Form.useForm();

  useMemo(() => {
    if (localStorage.scoutheimAdminToken) {
      Navigate(`/`);
    }
  }, [localStorage.scoutheimAdminToken]);

  const onFinish = (values) => {
    actionForgotPassword(values, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login-wrapper">
      <Card
        // className=""
        // title={<Image style={{margin:'auto'}} src={logo} preview={false} />}
        style={{
          width: 400,
          marginBottom: "100px",
        }}
      >
        {" "}
        <div className="login-logo-wrapper">
          <Image src={logo} preview={false} />
        </div>
        <p className="title">Forgot Password</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Row align="center" className="row-card">
            <Col span="24">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>

            <Col span={24} align="left">
              Provide your account email address to receive an email to reset
              your password.
            </Col>
            <Col span={24} style={{ marginBottom: 20 }} />

            <Col span={12} align="middle">
              <Form.Item>
                <Button loading={forgotPasswordLoader} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordLoader: state.auth.forgotPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionForgotPassword })(
  ForgotPassword
);
