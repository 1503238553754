import {
  Card,
  Row,
  Col,
  Button,
  Checkbox,
  Image,
  Form,
  Input,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import logo from "../../assets/img/main-wrapper/logo.png";
import { actionResetPassword } from "../../store/actions/authAction";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect } from "react-redux";

const { Title } = Typography;

const ResetPassword = (props) => {
  const Navigate = useNavigate();
  const { actionResetPassword, resetPasswordLoader } = props;
  const [form] = Form.useForm();

  const token = useLocation();
  const tokenCommon = token.search.split("=")[1];
  console.log(tokenCommon);

    useEffect(() => {
      if (localStorage.scoutheimAdminToken) {
        Navigate(`/`);
      }
    }, []);

  const onFinish = (values) => {
    actionResetPassword(
      { password: values.password, token: tokenCommon },
      form,
      Navigate
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-wrapper">
      <Card>
        <div className="login-logo-wrapper">
          <Image src={logo} preview={false} />
        </div>
        <p className="title">Reset Password</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            style={{ marginBottom: 15 }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                min: 8,
                message:
                  "passwords must be longer than or equal to 8 characters !",
              },
            ]}
          >
            <Input.Password placeholder="New password" />
          </Form.Item>

          <Form.Item
            name="confirm_new_password"
            style={{ marginBottom: 15 }}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your  new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={resetPasswordLoader}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    resetPasswordLoader: state.auth.resetPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionResetPassword })(ResetPassword);
