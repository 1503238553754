import { ConfigProvider, Image } from "antd";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import logo from "../src/assets/img/main-wrapper/logo.png";
import "../src/assets/scss/index.scss";
import Activities from "./components/activities/Activities";
import BusinessRegister from "./components/auth/BusinessRegister";
import ChangePassword from "./components/auth/ChangePassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/ResetPassword";
import UpdateProfile from "./components/auth/UpdateProfile";
import BusinessRequest from "./components/business-request/BusinessRequest";
import BusinessRequestDetail from "./components/business-request/BusinessRequestDetail";
import EditBusinessRequest from "./components/business-request/EditBusinessRequest";
import Business from "./components/business/Business";
import CreateBusiness from "./components/business/CreateBusiness";
import UpdateBusiness from "./components/business/UpdateBusiness";
import ViewBusiness from "./components/business/ViewBusiness";
import MainLayout from "./components/comman/MainLayout";
import NotFound from "./components/comman/NotFound";
import UnauthorizedComp from "./components/comman/UnauthorizedComp";
import setAuthToken from "./components/config/setAuthToken";
import Dashboard from "./components/dashboard/Dashboard";
import CreateDeal from "./components/deals/CreateDeal";
import DealDetail from "./components/deals/DealDetail";
import Deals from "./components/deals/Deals";
import CreateProperty from "./components/property/CreateProperty";
import Property from "./components/property/Property";
import UpdateProperty from "./components/property/UpdateProperty";
import ViewProperty from "./components/property/ViewProperty";
import Subscription from "./components/subscription/Subscription";
import CreateUser from "./components/users/CreateUser";
import UpdateUser from "./components/users/UpdateUser";
import UserDetail from "./components/users/UserDetail";
import Users from "./components/users/Users";
import { actionGetUserProfile } from "./store/actions/authAction";

const App = (props) => {
  const { actionGetUserProfile, userProfileData, userDataLoader } = props;

  useMemo(() => {
    if (localStorage.scoutheimAdminToken) {
      setAuthToken(localStorage.scoutheimAdminToken);
      actionGetUserProfile();
    }
  }, [localStorage.scoutheimAdminToken]);

  const RequireAuth = ({ children }) => {
    let location = useLocation();
    if (!localStorage.scoutheimAdminToken) {
      return React.createElement(Navigate, {
        to: "/login",
        state: { from: location },
        replace: true,
      });
    }

    if (
      localStorage.scoutheimAdminToken &&
      userProfileData &&
      userProfileData.roles !== "admin" &&
      userProfileData.live_business
    ) {
      const notAllowedPaths = [
        "/users-agents",
        "/create-new",
        "/update-user",
        "/view-user",
        "/business-request",
        "/update-business-request",
        "/properties",
        "/view-property",
        "/update-property",
        "/create-property",
        "/subscription",
        "/activities",
        "/business",
        "/create-business",
        "/update-business",
        "/view-business",
      ];

      if (notAllowedPaths.some((path) => location.pathname.startsWith(path))) {
        return React.createElement(Navigate, {
          to: "/unauthorized",
          state: { from: location },
          replace: true,
        });
      }
    }

    return children;
  };

  const withLayout = (WrappedComponent) => () =>
    (
      <MainLayout>
        <WrappedComponent />
      </MainLayout>
    );

  const withLayoutAndAuth = (WrappedComponent) => () =>
    (
      <MainLayout>
        <RequireAuth>
          <WrappedComponent />
        </RequireAuth>
      </MainLayout>
    );

  const WithLayoutAndAuthBusinessRegister = withLayoutAndAuth(BusinessRegister);
  const WithLayoutAndAuthDashboard = withLayoutAndAuth(Dashboard);
  const WithLayoutAndAuthDeals = withLayoutAndAuth(Deals);
  const WithLayoutAndAuthCreateDeal = withLayoutAndAuth(CreateDeal);
  const WithLayoutAndAuthDealDetail = withLayoutAndAuth(DealDetail);
  const WithLayoutAndAuthUsers = withLayoutAndAuth(Users);
  const WithLayoutAndAuthCreateUser = withLayoutAndAuth(CreateUser);
  const WithLayoutAndAuthUpdateUser = withLayoutAndAuth(UpdateUser);
  const WithLayoutAndAuthUserDetail = withLayoutAndAuth(UserDetail);
  const WithLayoutAndAuthBusinessRequest = withLayoutAndAuth(BusinessRequest);
  const WithLayoutAndAuthBusinessRequestDetail = withLayoutAndAuth(
    BusinessRequestDetail
  );
  const WithLayoutAndAuthEditBusinessRequest =
    withLayoutAndAuth(EditBusinessRequest);
  const WithLayoutAndAuthProperty = withLayoutAndAuth(Property);
  const WithLayoutAndAuthViewProperty = withLayoutAndAuth(ViewProperty);
  const WithLayoutAndAuthUpdateProperty = withLayoutAndAuth(UpdateProperty);
  const WithLayoutAndAuthCreateProperty = withLayoutAndAuth(CreateProperty);
  const WithLayoutAndAuthSubscription = withLayoutAndAuth(Subscription);
  const WithLayoutAndAuthActivities = withLayoutAndAuth(Activities);
  const WithLayoutAndAuthBusiness = withLayoutAndAuth(Business);
  const WithLayoutAndAuthCreateBusiness = withLayoutAndAuth(CreateBusiness);
  const WithLayoutAndAuthUpdateBusiness = withLayoutAndAuth(UpdateBusiness);
  const WithLayoutAndAuthViewBusiness = withLayoutAndAuth(ViewBusiness);
  const WithLayoutAndAuthChangePassword = withLayoutAndAuth(ChangePassword);
  const WithLayoutAndAuthUpdateProfile = withLayoutAndAuth(UpdateProfile);

  // No Auth
  const WithLayoutLogin = withLayout(Login);
  const WithLayoutResetPassword = withLayout(ResetPassword);
  const WithLayoutForgotPassword = withLayout(ForgotPassword);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            triggerColor: "white",
          },
        },
      }}
    >
      <BrowserRouter>
        {userDataLoader ? (
          <div className="loader-logo">
            <Image preview={false} src={logo} />
          </div>
        ) : (
          <Routes>
            {/* {userProfileData && userProfileData.roles === "admin" ? 
            ( */}

            <Route
              path="/register"
              element={<WithLayoutAndAuthBusinessRegister />}
            />

            <Route path="/" element={<WithLayoutAndAuthDashboard />} />
            <Route
              path="/deals-of-the-day"
              element={<WithLayoutAndAuthDeals />}
            />
            <Route path="/users-agents" element={<WithLayoutAndAuthUsers />} />

            <Route
              path="/create-new"
              element={<WithLayoutAndAuthCreateUser />}
            />
            <Route
              path="/update-user/:userId"
              element={<WithLayoutAndAuthUpdateUser />}
            />
            <Route
              path="/view-user/:userId"
              element={<WithLayoutAndAuthUserDetail />}
            />

            <Route
              path="/business-request"
              element={<WithLayoutAndAuthBusinessRequest />}
            />
            <Route
              path="/business-request/:id"
              element={<WithLayoutAndAuthBusinessRequestDetail />}
            />
            <Route
              path="/update-business-request/:id"
              element={<WithLayoutAndAuthEditBusinessRequest />}
            />

            <Route path="/properties" element={<WithLayoutAndAuthProperty />} />
            <Route
              path="/view-property/:propertyId"
              element={<WithLayoutAndAuthViewProperty />}
            />
            <Route
              path="/update-property/:propertyId"
              element={<WithLayoutAndAuthUpdateProperty />}
            />
            <Route
              path="/create-property"
              element={<WithLayoutAndAuthCreateProperty />}
            />
            <Route
              path="/subscription"
              element={<WithLayoutAndAuthSubscription />}
            />
            <Route
              path="/activities"
              element={<WithLayoutAndAuthActivities />}
            />
            <Route path="/business" element={<WithLayoutAndAuthBusiness />} />
            <Route
              path="/create-business"
              element={<WithLayoutAndAuthCreateBusiness />}
            />
            <Route
              path="/update-business/:businessId"
              element={<WithLayoutAndAuthUpdateBusiness />}
            />
            <Route
              path="/view-business/:businessId"
              element={<WithLayoutAndAuthViewBusiness />}
            />

            <Route
              path="/deals-of-the-day"
              element={<WithLayoutAndAuthDeals />}
            />
            <Route
              path="/create-deal"
              element={<WithLayoutAndAuthCreateDeal />}
            />
            <Route
              path="/view-deal/:id"
              element={<WithLayoutAndAuthDealDetail />}
            />
            {/* <Route
                path="/update-deal/:id"
                element={
                  <RequireAuth>
                    <UpdateDeal />
                  </RequireAuth>
                }
              /> */}
            <Route path="/login" element={<WithLayoutLogin />} />
            <Route
              path="/change-password/:id"
              element={<WithLayoutAndAuthChangePassword />}
            />
            {/*  <Route
                path="/change-password"
                element={
                  <RequireAuth>
                    <ChangePassword />
                  </RequireAuth>
                }
              />
 */}
            <Route
              path="/reset-password"
              element={<WithLayoutResetPassword />}
            />
            <Route
              path="/forgot-password"
              element={<WithLayoutForgotPassword />}
            />

            <Route
              path="/update-profile"
              element={<WithLayoutAndAuthUpdateProfile />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/unauthorized" element={<UnauthorizedComp />} />
          </Routes>
        )}
      </BrowserRouter>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    userDataLoader: state.auth.userDataLoader,
  };
};

export default connect(mapStateToProps, { actionGetUserProfile })(App);
