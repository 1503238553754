import { CalendarOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Image,
  Descriptions,
  List,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import logo from "../../assets/img/main-wrapper/user.jpg";
import verifiedIcon from "../../assets/img/icon/verify.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { actionGetBusinessDetail, actionDeleteBusiness } from "../../store/actions/businessAction";
import user from "../../assets/img/main-wrapper/user.jpg";
import { removeUnderScore } from "../../common/function";

const { Title, Text } = Typography;

const ViewBusiness = (props) => {
  const Navigate = useNavigate();
  const { actionGetBusinessDetail, businessDetailData, businessDetailLoader, actionDeleteBusiness, deleteBusinessLoader } = props;
  const { businessId } = useParams();
  useEffect(() => {
    actionGetBusinessDetail(businessId);
  }, []);


  const confirm = (id) => {
    actionDeleteBusiness(id);
  };

  const data = [
    { label: "Business Name", value: businessDetailData.business_name }, 
    { label: "Business email", value: businessDetailData.business_email }, 
    { label: "Business Mobile", value: businessDetailData.business_mobile }, 
    { label: "Business Office Phone", value: businessDetailData.business_office_phone }, 
    { label: "Business Website", value: businessDetailData.business_website }, 
    { label: "Broker Name", value: businessDetailData.broker_name }, 
    { label: "Business Address", value: businessDetailData.business_address }, 
  ];

  return (
    <>
      <Spin spinning={businessDetailLoader}>
        <div className="view-property">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
              <Card
                className="form-card-border list-layout-li"
                align="middle"
                title="Business Logo"
                bordered={false}
                style={{ width: "100%" }}
              >
                <Image preview={{ mask: "" }} src={businessDetailData.business_logo} style={{border:'3px solid #FF9A3E',borderRadius:'50%',cursor:'pointer'}} width={100} height={100} />
              </Card>
              </Col>
              <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li"
                    align="middle"
                    title="Owner's Details"
                    bordered={false}
                    style={{ width: "100%" }}
                  >
                    <Image preview={{ mask: "" }} src={businessDetailData && businessDetailData.user && businessDetailData.user.profile_pic} style={{ border: '3px solid #FF9A3E', borderRadius: '50%',cursor:'pointer' }} width={100} height={100} />
                    <p className="user-name">{businessDetailData && businessDetailData.user && `${businessDetailData.user.first_name} ${businessDetailData.user.last_name} - ${businessDetailData.user.roles}`}</p>
                    <p className="user-email">{businessDetailData && businessDetailData.user && businessDetailData.user.email}</p>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <Card
                className="body-padding form-card-border list-layout-li"
                title="More Details"
                bordered={false}
                style={{ width: "100%" }}
              >
                <Descriptions
                  title=""
                  layout="horizontal"
                  colon={true}
                  bordered
                  column={1}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <Descriptions.Item
                        key={index}
                        label={item.label}
                        style={{
                          background:
                            index % 2 !== 0 ? "rgba(239, 239, 239, 1)" : "",
                        }} // Set color dynamically or default to black
                      >
                        {item.value ? item.value : "-"}
                      </Descriptions.Item>
                    ))}
                </Descriptions>
                <br />
                <br />
                <Link to={`/update-business/${businessDetailData._id}`}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                  >
                    Update
                  </Button>
                </Link>

                <Popconfirm
                  okButtonProps={{
                    loading: deleteBusinessLoader,
                  }}
                  placement="topLeft"
                  title="Are you sure to delete this business?"
                  onConfirm={() => confirm(businessDetailData._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                    icon={<DeleteOutlined />}
                    type="danger"
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    businessDetailData: state.business.businessDetailData,
    businessDetailLoader: state.business.businessDetailLoader,
    deleteBusinessLoader: state.business.deleteBusinessLoader
  };
};

export default connect(mapStateToProps, {
    actionGetBusinessDetail,
    actionDeleteBusiness
})(ViewBusiness);
