import React, { useEffect, useState } from "react";
import {
  EllipsisOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import {
  actionDeleteUser,
  actionVerifyAgent,
  actionGetAllUser,
} from "../../store/actions/userAction";
import {
  Col,
  Row,
  Image,
  Space,
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Table,
  Tag,
  Dropdown,
  Popconfirm,
  Spin,
} from "antd";
import userIcon from "../../assets/img/dashboard/user-card.png";
import userImage from "../../assets/img/main-wrapper/user.jpg";
import verifiedIcon from "../../assets/img/icon/verify.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeUnderScore } from "../../common/function";

const Users = (props) => {
  const {
    actionGetAllUser,
    actionDeleteUser,
    actionVerifyAgent,
    usersData,
    usersCount,
    usersLoader,
    deleteUserLoader,
    verifyAgentLoading,
  } = props;

  // console.log(usersData, "usersData");

  const [search, setSearch] = useState();
  const [role, setRole] = useState();
  const [status, setStatus] = useState();
  const [subscriptionPlan, setSubscriptionPlan] = useState();

  const onFinish = (values) => {
    // console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    actionGetAllUser(0, 10);
  }, []);

  const confirm = (id) => {
    actionDeleteUser(id);
  };

  const onHandleVerification = (id, type) => {
    actionVerifyAgent(id, type);
  };

  const data = [];
  usersData &&
    usersData.length > 0 &&
    usersData.map((d, index) => {
      let icon =
        d.roles === "user" || d.roles === "admin" || d.roles === "agent" ? (
          <img
            src={verifiedIcon}
            className=""
            width={18}
            style={{ marginLeft: "8px" }}
          />
        ) : (
          ""
        );
      data.push({
        key: index,
        image: d.profile_pic,
        name: d.first_name + " " + d.last_name,
        email: d.email,
        mobile: d.mobile ? d.mobile : "-",
        source: d.hear_us_from ? d.hear_us_from : "-",

        // gender: d.gender ? "Male" : "Female",
        gender:
          // d.gender === "M" ? "Male" : d.gender === "F" ? "Female" : "Other",
          d.gender === "M"
            ? "Male"
            : d.gender === "F"
            ? "Female"
            : d.gender === "O"
            ? "Other"
            : "-",
        // dob: d.dob,
        roles: (
          <span style={{ display: "flex", alignItems: "center" }}>
            {removeUnderScore(d.roles)}
            {icon}
          </span>
        ),
        id: d._id,
        role: d.roles,
        // status: d.subscription.status ? d.subscription.status : "-",
        // subscription_type: d.subscription?.subscription_plan?.subscription_type
        //   ? d.subscription?.subscription_plan?.subscription_type
        //   : "-",
        status:
          d && d.subscription && d.subscription.status
            ? d.subscription.status
            : "-",
      });
    });

  useEffect(() => {
    // console.log(data, "data");
    // console.log(usersData, "userdata");
  }, [data, usersData]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Trial":
        return "#4CAF50"; // Green (Success)
      case "Monthly":
        return "#FFEB3B"; // Yellow (Warning)
      case "Cancelled":
        return "#F44336"; // Red (Error)
      case "Yearly":
        return "#2196F3"; // Blue (Info)
      case "Active":
        return "#4CAF50"; // Green (Success)
      case "Inactive":
        return "#9E9E9E"; // Gray (Neutral)
      default:
        return "#607D8B"; // Default (Blue-Gray)
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text) => (
        <Image
          src={text}
          width={50}
          height={50}
          preview={{ mask: "" }}
          className="user-image"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Subscription",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const status = record.status || "-";
        const subscriptionTypeRaw = record.subscription_type || "";
        const subscriptionType =
          subscriptionTypeRaw.charAt(0).toUpperCase() +
          subscriptionTypeRaw.slice(1).toLowerCase();
        const color = getStatusColor(status);

        return (
          <>
            <Tag color={color}>{status}</Tag>
            {subscriptionTypeRaw && subscriptionTypeRaw !== "-" ? (
              <span style={{ marginLeft: 8 }}>({subscriptionType})</span>
            ) : null}{" "}
          </>
        );
      },
    },

    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "80px",
      key: "action",
      render: (_, item) => (
        <>
          <Dropdown
            placement="bottom"
            menu={{
              items: [
                {
                  label: (
                    <span>
                      <Link
                        to={`/view-user/${item.id}`}
                        style={{ color: "black" }}
                      >
                        <EyeOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        View
                      </Link>
                    </span>
                  ),
                },
                {
                  label: (
                    <span>
                      <Link
                        to={`/update-user/${item.id}`}
                        style={{ color: "black" }}
                      >
                        <EditOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Edit
                      </Link>
                    </span>
                  ),
                },
                {
                  label: (
                    <span>
                      <Link
                        to={`/change-password/${item.id}`}
                        style={{ color: "black" }}
                      >
                        <KeyOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Change Password
                      </Link>
                    </span>
                  ),
                },
                item.role === "bot_agent" && {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteUserLoader,
                      }}
                      placement="topLeft"
                      title="Are you sure to approve this Agent?"
                      onConfirm={() => onHandleVerification(item.id, "Approve")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span>
                        <CheckOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Approve agent
                      </span>
                    </Popconfirm>
                  ),
                },
                item.role === "bot_agent" && {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteUserLoader,
                      }}
                      placement="topLeft"
                      title="Are you sure to disapprove this Agent?"
                      onConfirm={() =>
                        onHandleVerification(item.id, "Disapproved")
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <span>
                        <CloseOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Disapprove agent
                      </span>
                    </Popconfirm>
                  ),
                },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteUserLoader,
                      }}
                      placement="topLeft"
                      title="Are you sure to delete this User?"
                      onConfirm={() => confirm(item.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span style={{ color: "black" }}>
                        <DeleteOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Delete
                      </span>
                    </Popconfirm>
                  ),
                },
              ],
            }}
          >
            <a>
              <EllipsisOutlined style={{ fontSize: "20px" }} />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  const onClickPaginationNumber = (e) => {
    actionGetAllUser(
      e.current - 1,
      e.pageSize,
      search,
      role,
      "",
      status,
      subscriptionPlan
    );
  };
  const onFinishSearch = (value) => {
    setSearch(value.search ? value.search : "");
    setRole(value.role ? value.role : "");
    setStatus(value.status ? value.status : "");
    setSubscriptionPlan(value.subscription_plan ? value.subscription_plan : "");

    actionGetAllUser(
      0,
      10,
      value.search,
      value.role,
      "",
      value.status,
      value.subscription_plan // Use the correct key here
    );
  };

  return (
    <div>
      <Spin spinning={usersLoader}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row className="main-card-wrapper" gutter={16}>
            <Col span={8}>
              <div className="card-blue">
                <Row>
                  <Col span={20} className="text-card">
                    <p className="font-title">Users</p>
                    <p className="font-text">
                      {usersCount && usersCount.users ? usersCount.users : 0}
                    </p>
                  </Col>
                  <Col span={4} className="image-card-blue">
                    <Image src={userIcon} preview={{ mask: "" }} />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div className="card-yellow">
                <Row>
                  <Col span={20} className="text-card">
                    <p className="font-title">Agents</p>
                    <p className="font-text">
                      {usersCount && usersCount.agents ? usersCount.agents : 0}
                    </p>
                  </Col>
                  <Col span={4} className="image-card-yellow">
                    <Image src={userIcon} preview={false} />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div className="card-pink">
                <Row>
                  <Col span={20} className="text-card">
                    <p className="font-title">Admin</p>
                    <p className="font-text">
                      {usersCount && usersCount.admins ? usersCount.admins : 0}
                    </p>
                  </Col>
                  <Col span={4} className="image-card-pink">
                    <Image src={userIcon} preview={false} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row
            className="main-card-wrapper"
            gutter={10}
            justify="space-around"
            align="middle"
          >
            <Col span={3} style={{ fontWeight: "bold", fontSize: "12px" }}>
              Users/Agents
            </Col>
            <Col span={18} align="middle">
              {" "}
              <Form
                name="basic"
                layout="inline"
                onFinish={onFinishSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{ justifyContent: "center" }}
              >
                <Form.Item name="search">
                  <Input placeholder="Search Here..." />
                </Form.Item>

                <Form.Item name="role" className="fixed-select">
                  <Select
                    showSearch
                    placeholder="Select Role"
                    optionFilterProp="children"
                    allowClear
                    // onChange={onChange}
                    // onSearch={onSearch}
                    // style={{ minWidth: "100px" }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "admin",
                        label: "Admin",
                      },
                      {
                        value: "agent",
                        label: "Agent",
                      },
                      {
                        value: "user",
                        label: "User",
                      },
                      {
                        value: "bot",
                        label: "Bot",
                      },
                      {
                        value: "bot_agent",
                        label: "Bot Agent",
                      },
                      {
                        value: "Business User",
                        label: "Business User",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item name="status" className="fixed-select">
                  <Select
                    showSearch
                    placeholder="Status"
                    optionFilterProp="children"
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => setStatus(value)}
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "Canceled", label: "Canceled" },
                      { value: "Inactive", label: "Inactive" },
                    ]}
                  />
                </Form.Item>

                <Form.Item name="subscription_plan" className="fixed-select">
                  <Select
                    showSearch
                    placeholder="Subscription Plan"
                    optionFilterProp="children"
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => setSubscriptionPlan(value)}
                    options={[
                      { value: "TRIAL", label: "Trial" },
                      { value: "MONTHLY", label: "Monthly" },
                      { value: "YEARLY", label: "Yearly" },
                    ]}
                  />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit">Search</Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={3} align="right">
              <Link to="/create-new">
                <Button>Create New</Button>
              </Link>
            </Col>
            <Col span={24} style={{ marginTop: "10px" }}>
              <Table
                columns={columns}
                // loading={usersLoader}
                sticky={true}
                pagination={{
                  total: usersCount.records, // add total Property Count Here
                  hideOnSinglePage: true,
                }}
                size="small"
                dataSource={data}
                onChange={(e) => onClickPaginationNumber(e)}
                scroll={{
                  x: 1500,
                  y: window.innerWidth < 1500 ? "40vh" : "50vh",
                }}
              />
            </Col>
          </Row>
        </Space>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usersLoader: state.user.usersLoader,
    usersData: state.user.usersData,
    deleteUserLoader: state.user.deleteUserLoader,
    usersCount: state.user.usersCount,
    verifyAgentLoading: state.user.verifyAgentLoading,
  };
};

export default connect(mapStateToProps, {
  actionGetAllUser,
  actionDeleteUser,
  actionVerifyAgent,
})(Users);
