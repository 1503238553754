import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
  Tabs,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../assets/img/icon/file.png";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { actionAddUser } from "../../store/actions/userAction";
const { Search } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateUser = (props) => {
  const { actionAddUser, addUserLoader } = props;

  const [userPicUrl, setUserPicUrl] = useState();
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Check if all form values are filled
    const isFormFilled = Object.values(formValues).every(
      (value) => value !== ""
    );

    // Update the button disabled state
    setIsButtonDisabled(!isFormFilled);
  }, [formValues]);

  const handleFormChange = (changedValues, allValues) => {
    setFormValues(allValues);
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setUserPicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  // useEffect(() => {
  //   actionAddUser();
  // }, []);

  const onFinish = (values) => {
    const request = {
      // ...values,
      // profile_pic: values.profile_pic.file.response.file.filename,
      ...values,
      // profile_pic: userPicUrl
      //   ? userPicUrl.split("/").reverse()[0]
      //   : values.profile_pic.file.response.file.filename,
        profile_pic: userPicUrl
        ? userPicUrl.split("/").reverse()[0]
        : "",
      // date_of_birth: dayjs(values.date_of_birth).format("YYYY-MM-DD"),
      is_real_estate_egent: values.roles === "agent" ? true : false,
    };
    actionAddUser(request, Navigate);
  };

  /*  const uploadButton = (
     <div>
       {false ? <LoadingOutlined /> : <PlusOutlined />}
       <div style={{ marginTop: 8 }}>Upload</div>
     </div>
   );
  */

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinishFailed = (errorInfo) => {};
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="form-card-border"
            title={<h3>Create New</h3>}
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  initialValues={{ country_phone_code: "+91" }}
                  onValuesChange={handleFormChange}
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="First Name"
                        name="first_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for first Name"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        colon={false}
                        name="last_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for last Name"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Email"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            // message: "Please enter email!",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label="Mobile"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="country_phone_code"
                        rules={[
                          {
                            required: true,
                            message: "Please select country code.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="+91"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "+91",
                              label: "IN +91",
                            },
                            {
                              value: "+1",
                              label: "US +1",
                            },
                            {
                              value: "+1",
                              label: "CN +1",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <p style={{ color: "transparent" }}>transparent </p>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (value && value.length == 10) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(
                          //       new Error("Invalid Mobile Number!")
                          //     );
                          //   },
                          // }),
                        ]}
                      >
                        <Input
                          maxLength={2}
                          // autoFocus="none"
                          type={"number"}
                          placeholder="Enter Mobile Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Password"
                        colon={false}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter password!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length >= 8
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter minimum 8 characters for Password"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input.Password placeholder="Enter Password" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Roles"
                        colon={false}
                        name="roles"
                        rules={[
                          {
                            required: true,
                            message: "Please select Roles.!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Role"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "user",
                              label: "User",
                            },
                            {
                              value: "admin",
                              label: "Admin",
                            },
                            {
                              value: "agent",
                              label: "Agent",
                            },
                            {
                              value: "bot",
                              label: "Bot",
                            },
                            {
                              value: "bot_agent",
                              label: "Bot Agent",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        // normalize={(value) => value.trim()}
                        label="Date Of Birth"
                        colon={false}
                        name="dob"
                        rules={[
                          {
                            required: true,
                            message: "Please enter date of birth!",
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Gender"
                        colon={false}
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender.!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Gender"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "M",
                              label: "Male",
                            },
                            {
                              value: "F",
                              label: "Female",
                            },
                            {
                              value: "O",
                              label: "Other",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Source"
                        colon={false}
                        name="hear_us_from"
                        rules={[
                          {
                            required: true,
                            message: "Please select Source!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Source"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Search Engine(Google,yahoo,etc.)",
                              label: "Search Engine(Google,yahoo,etc.)",
                            },
                            {
                              value: "Recommended by friend or colleague",
                              label: "Recommended by friend or colleague",
                            },
                            {
                              value: "Social media",
                              label: "Social media",
                            },
                            {
                              value: "Blog or publication",
                              label: "Blog or publication",
                            },
                            {
                              value: "Other",
                              label: "Other",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {/* <br /> */}
                      <Form.Item
                        normalize={(value) => value.trim()}
                        name="profile_pic"
                        label="Profile Picture"
                        rules={[
                          {
                            required: false,
                            message: "Please select image!",
                          },
                        ]}
                      >
                        <div>
                          <>
                            <ImgCrop
                              rotationSlider
                              aspect={4 / 3}
                              fillColor="white"
                            >
                              <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={BASE_URL_UPLOAD}
                                headers={{
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem("scoutheimAdminToken"),
                                }}
                                onChange={handleChangeUserPic}
                              >
                                {userPicUrl ? (
                                  <img
                                    src={userPicUrl}
                                    alt="avatar"
                                    style={{
                                      width: "85%",
                                      height: "85%",
                                    }}
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </Upload>
                            </ImgCrop>
                          </>
                        </div>
                        {/* {userPicUrl && (
                          <Image
                            src={userPicUrl}
                            alt="file"
                            preview={false}
                            width={200}
                            style={{ marginTop: "10px" }}
                          />
                        )} */}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addUserLoader}
                          align="center"
                          htmlType="submit"
                          disabled={isButtonDisabled}
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addUserLoader: state.user.addUserLoader,
    addUserData: state.user.addUserData,
  };
};

export default connect(mapStateToProps, {
  actionAddUser,
})(CreateUser);
