import { Card, Image, Button, message, Upload, Form, Row, Input, Col, InputNumber } from "antd";
import React, { useState } from "react";
import logo from "../../assets/img/main-wrapper/logo.png";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { actionLogin } from "../../store/actions/authAction";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL_UPLOAD } from "../config/web-config";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const Login = (props) => {
    const Navigate = useNavigate();
    const { actionLogin, LoginLoader } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(info.file.response.file.path);
            });
        }
    };
    const splitUrl = (url) => {
        return url && url.split('/').reverse()[0];
    }
    const onFinish = (values) => {
        const req = {
            ...values,
            iamge: imageUrl ? splitUrl(imageUrl) : splitUrl(values.file.response.file.path)
        }
        console.log({ req });
        //actionLogin(values, Navigate);
    };
    /* 
        useEffect(() => {
            if (localStorage.scoutheimAdminToken) {
                Navigate(`/`);
            }
            if (scoutheimEmail && scoutheimPassword) {
                form.setFieldsValue({
                    email: scoutheimEmail,
                    password: scoutheimPassword,
                });
            }
        }, []); */
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <div className="business-wrapper">
            <Card>
                <div className="login-logo-wrapper">
                    <Image src={logo} preview={false} />
                </div>
                <p className="title">Sign Up</p>
                <Form layout="vertical"
                    requiredMark={true}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={[16, 5]}>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {

                                        required: true,
                                        message: "Please input your First name!",
                                    },
                                ]}
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Last name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="business_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Business Name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Name of Business" />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Business Address!",
                                    },
                                ]}
                            >
                                <Input placeholder="Address of Business" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="business_email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: "Please input your Business Email!",
                                    },
                                ]}
                            >
                                <Input placeholder="Business Email" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="cell_phoneno"
                                normalize={(value) =>
                                    value.replace(/[^0-9]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Cell Phone Number!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && (value.length !== 10)) {
                                                return Promise.reject("Please enter a valid 10-digit mobile number! ");
                                            }

                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <Input controls={false} style={{ width: '100%' }} placeholder="Cell Phone Number" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="office_phone_no"
                                normalize={(value) =>
                                    value.replace(/[^0-9]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Office Phone Number!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && (value.length !== 10)) {
                                                return Promise.reject("Please enter a valid 10-digit mobile number!");
                                            }

                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <Input controls={false} style={{ width: '100%' }} placeholder="Office Phone Number" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Form.Item
                                name="website"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Website Link!",
                                    },
                                ]}
                            >
                                <Input controls={false} style={{ width: '100%' }} placeholder="Website Link" />
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item
                                name="picture"
                                label="Picture of Agent"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Upload Picture of Agent!",
                                    },
                                ]}
                            >

                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={BASE_URL_UPLOAD}
                                    headers={{
                                        Authorization:
                                            "Bearer " +
                                            localStorage.getItem(
                                                "scoutheimAdminToken"
                                            ),
                                    }}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>
                        </Col>


                        <Col span={24} align="middle" justify="center" style={{ margin: "10px 0", textAlign: 'center' }}>

                            <div className="">

                                <Form.Item>
                                    <Button style={{ width: "50%", }} type="primary" htmlType="submit" loading={LoginLoader}>
                                        Sign Up
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <span style={{ fontWeight: 400, marginRight: 10 }}>Existing User</span>
                                <Link
                                    style={{ color: "#FF9A3E", fontWeight: 600 }}
                                    className="link-color"
                                    to="/login"
                                >
                                    Sign in
                                </Link>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Card>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        LoginLoader: state.auth.LoginLoader,
    };
};

export default connect(mapStateToProps, { actionLogin })(Login);
