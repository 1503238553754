
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { actionGetBusinessDetail, actionUpdateBusiness } from "../../store/actions/businessAction";
import { actionGetAllUser } from "../../store/actions/userAction";
const { Search } = Input;
const { TextArea } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UpdateBusiness = (props) => {
  const {
    actionGetBusinessDetail,
    businessDetailData,
    updateBusinessLoader,
    actionUpdateBusiness,
    actionGetAllUser,
    usersData,
  } = props;

  const [loading, setLoading] = useState(false);
  const [userPicUrl, setUserPicUrl] = useState();
  const [UserShow, setUserShow] = useState(false);
  const [userId, setUserId] = useState("");
  const { businessId } = useParams();
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  useEffect(() => {
    actionGetBusinessDetail(businessId);
    actionGetAllUser(0, 500, null, ['user', 'admin', 'agent'], true);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      business_name: businessDetailData.business_name,
      business_email: businessDetailData.business_email,
      business_mobile: businessDetailData.business_mobile,
      business_office_phone: businessDetailData.business_office_phone,
      business_website: businessDetailData.business_website,
      broker_name: businessDetailData.broker_name,
      business_logo: businessDetailData.business_logo,
      business_address: businessDetailData.business_address,
      user: businessDetailData.user && businessDetailData.user.first_name + " " + businessDetailData.user.last_name
    });
    setUserPicUrl(businessDetailData.business_logo);
  }, [businessDetailData]);

  useMemo(() => { }, [businessDetailData]);

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setUserPicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {


    const id = usersData && usersData.find(user => user.first_name + " " + user.last_name === values.user);

    const newId = id ? id._id : null;

    const request = {
      ...values,
      business_logo: userPicUrl
        ? userPicUrl.split("/").reverse()[0]
        : values.business_logo.file.response.file.filename,
      user: UserShow ? userId : newId,
    };
    actionUpdateBusiness(request, Navigate, businessId);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const users = [];
  usersData && usersData.map((user, index) => {
    if (user/* .roles === "user" */) {
      users.push({
        key: index,
        label: user.first_name + " " + user.last_name,
        value: user._id,
      });
    }
  })
  const handleUserChange = (e, data) => {
    // console.log(data);

    setUserShow(true);
    setUserId(data.value);
  }

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="form-card-border"
            title={<h3>Update Business</h3>}
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label="Select Owner"
                        colon={false}
                        name="user"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Owner!",
                          },
                        ]}
                      >
                        <Select
                          // mode="multiple"
                          placeholder="Select Owner"
                          allowClear
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          onChange={handleUserChange}
                          filterOption={filterOption}
                          options={users}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Business Name"
                        name="business_name"
                        // normalize={(value) =>
                        //   value.replace(/[^a-zA-Z]/g, "").trim()
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Please enter maximum 15 characters for Business Name"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Business Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Business Email"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="business_email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {/* <p style={{ color: "transparent" }}>transparent </p> */}
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="business_mobile"
                        label="Business Mobile no"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (value && value.length == 10) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(
                          //       new Error("Invalid Mobile Number!")
                          //     );
                          //   },
                          // }),
                        ]}
                      >
                        <Input
                          maxLength={2}
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Business Mobile Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Office Phone no"
                        colon={false}
                        name="business_office_phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Office No!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (value && value.length == 10) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(
                          //       new Error("Invalid Mobile Number!")
                          //     );
                          //   },
                          // }),
                        ]}
                      >
                        <Input type={"number"} placeholder="Enter Office Mob no." />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Business Website"
                        colon={false}
                        name="business_website"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.trim() === '') {
                                // If no value is added, return success
                                return Promise.resolve();
                              }
                              if (/^(https?:\/\/)?[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(value)) {
                                // If value is a valid URL, return success
                                return Promise.resolve();
                              }
                              // If value is not a valid URL, return error message
                              return Promise.reject(new Error('Invalid Website URL'));
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Enter Website" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Broker Name"
                        colon={false}
                        name="broker_name"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Broker Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Broker Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Business Address"
                        colon={false}
                        name="business_address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Business address!",
                          },
                        ]}
                      >
                        {/* <Input.Password placeholder="Enter Business Address" /> */}
                        <TextArea
                          style={{
                            height: 100,
                            resize: "none",
                          }}
                          placeholder="Business Address"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        name="business_logo"
                        label="Business Logo"
                        rules={[
                          {
                            required: false,
                            message: "Please select image!",
                          },
                        ]}
                      >
                        <div>
                          <>
                            <ImgCrop
                              rotationSlider
                              aspect={4 / 3}
                              fillColor="white"
                            >
                              <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={BASE_URL_UPLOAD}
                                headers={{
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                      "scoutheimAdminToken"
                                    ),
                                }}
                                onChange={handleChangeUserPic}
                              >
                                {userPicUrl ? (
                                  <img
                                    src={userPicUrl}
                                    alt="avatar"
                                    style={{
                                      width: '85%',
                                      height: '85%',
                                    }}
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </Upload>
                            </ImgCrop>
                          </>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={updateBusinessLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    updateBusinessLoader: state.business.updateBusinessLoader,
    businessDetailLoader: state.business.businessDetailLoader,
    businessDetailData: state.business.businessDetailData,
    usersData: state.user.usersData,
  };
};

export default connect(mapStateToProps, {
  actionGetBusinessDetail,
  actionUpdateBusiness,
  actionGetAllUser,
})(UpdateBusiness);
