import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  console.log("Not Found");
  return (
    <>
      <Result
        status="404"
        title="Not Found"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back to Dashboard</Button>{" "}
          </Link>
        }
      />
    </>
  );
};

export default NotFound;
