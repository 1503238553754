import React, { Fragment, useState, useEffect, useMemo } from "react";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  message,
  Upload,
  Select,
  DatePicker,
  Spin,
  Tabs,
} from "antd";
import { connect } from "react-redux";
import { actionUpdateUserProfile } from "../../store/actions/authAction";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { actionUpdateBusiness } from "../../store/actions/businessAction";
import TextArea from "antd/es/input/TextArea";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UpdateProfile = (props) => {
  const {
    updateProfileLoader,
    actionUpdateBusiness,
    updateBusinessLoader,
    actionUpdateUserProfile,
    userProfileData,
  } = props;

  const [loading, setLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState();
  const [businessProfilePicUrl, setBusinessProfilePicUrl] = useState();
  const [isBusiness, setIsBusiness] = useState(false);

  const Navigate = useNavigate();

  const [form] = Form.useForm();
  const [businessForm] = Form.useForm();

  useMemo(() => {}, [userProfileData]);

  console.log({ userProfileData });

  useEffect(() => {
    userProfileData &&
      form.setFieldsValue({
        first_name: userProfileData.first_name,
        last_name: userProfileData.last_name,
        email: userProfileData.email,
        gender: userProfileData.gender,
        profile_pic: userProfileData.profile_pic,
        country_phone_code: userProfileData.country_phone_code,
        mobile: userProfileData.mobile,
        country_of_residence: userProfileData.country_of_residence,
        nationality: userProfileData.nationality,
        dob: userProfileData.dob ? dayjs(userProfileData.dob) : undefined,
      });

    userProfileData.live_business &&
      businessForm.setFieldsValue({
        business_name: userProfileData.live_business.business_name,
        business_email: userProfileData.live_business.business_email,
        business_office_phone:
          userProfileData.live_business.business_office_phone,
        business_mobile: userProfileData.live_business.business_mobile,
        business_website: userProfileData.live_business.business_website,
        business_address: userProfileData.live_business.business_address,
        broker_name: userProfileData.live_business.broker_name,
      });
    setProfilePicUrl(userProfileData.profile_pic);
    setBusinessProfilePicUrl(
      userProfileData.live_business &&
        userProfileData.live_business.business_logo
    );
  }, [userProfileData]);

  const handleChangeBusinessUserPic = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setBusinessProfilePicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  console.log(businessProfilePicUrl);

  const onFinish = (values) => {
    const data = {
      ...values,
      profile_pic: profilePicUrl
        ? profilePicUrl.split("/").reverse()[0]
        : values.profile_pic.file.response.file.filename,
      dob: dayjs(values.dob).format("YYYY-MM-DD"),
    };
    actionUpdateUserProfile(data, userProfileData._id);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onChange = (key) => {
    console.log(key);
  };

  const businessId =
    userProfileData &&
    userProfileData.live_business &&
    userProfileData.live_business._id;

  const onFinishBusiness = (values) => {
    const req = {
      ...values,
      user: userProfileData._id,
      business_logo: businessProfilePicUrl
        ? businessProfilePicUrl.split("/").reverse()[0]
        : values.business_logo.file.response.file.filename,
    };
    console.log({ req });
    actionUpdateBusiness(req, "", businessId);
  };

  const onFinishFailedBusiness = (error) => {
    console.log({ error });
  };

  const UpdateProfile = () => {
    return (
      <Form
        autoComplete="off"
        requiredMark={"optional"}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              colon={false}
              name="first_name"
              normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
                {
                  validator: (_, value) =>
                    value.length <= 15
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please enter maximum 15 characters for first Name"
                          )
                        ),
                },
              ]}
            >
              <Input autoFocus="none" type={"text"} placeholder="first name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon={false}
              label="Last Name"
              name="last_name"
              normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
              rules={[
                {
                  required: true,
                  message: "Please enter last name!",
                },
                {
                  validator: (_, value) =>
                    value.length <= 15
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please enter maximum 15 characters for last Name"
                          )
                        ),
                },
              ]}
            >
              <Input autoFocus="none" type={"text"} placeholder="last name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              colon={false}
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  // message: "Please enter email!",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input autoFocus="none" type={"text"} placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Mobile"
              normalize={(value) => value.trim()}
              colon={false}
              name="country_phone_code"
              rules={[
                {
                  required: true,
                  message: "Please select country code.!",
                },
              ]}
            >
              <Select
                disabled={true}
                showSearch
                placeholder="+91"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "+91",
                    label: "IN +91",
                  },
                  {
                    value: "+1",
                    label: "US +1",
                  },
                  {
                    value: "+1",
                    label: "CN +1",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <p style={{ color: "transparent" }}>transparent </p>
            <Form.Item
              normalize={(value) => value.trim()}
              colon={false}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile!",
                },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     if (value && value.length == 10) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(new Error("Invalid Mobile Number!"));
                //   },
                // }),
              ]}
            >
              <Input
                disabled={true}
                maxLength={2}
                autoFocus="none"
                type={"number"}
                placeholder="Enter Mobile Number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              // normalize={(value) => value.trim()}
              colon={false}
              label="Date Of Birth"
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please enter date of birth!",
                },
              ]}
            >
              <DatePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              label="Gender"
              colon={false}
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select gender.!",
                },
              ]}
            >
              <Select
                placeholder="Select Gender"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "M",
                    label: "Male",
                  },
                  {
                    value: "F",
                    label: "Female",
                  },
                  {
                    value: "O",
                    label: "Other",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              colon={false}
              label="Nationality"
              name="nationality"
              rules={[
                {
                  required: false,
                  message: "Please select nationality.!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select nationality"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Indian",
                    label: "Indian",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              colon={false}
              label="Country of Residence"
              name="country_of_residence"
              rules={[
                {
                  required: false,
                  message: "Please select country of residence.!",
                },
              ]}
            >
              <Input placeholder="country of residence" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              name="profile_pic"
              label="Profile Picture"
              rules={[
                {
                  required: profilePicUrl ? false : true,
                  message: !profilePicUrl && "Please select image!",
                },
              ]}
            >
              <div>
                <>
                  <ImgCrop rotationSlider aspect={4 / 3} fillColor="white">
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      headers={{
                        Authorization:
                          "Bearer " +
                          localStorage.getItem("scoutheimAdminToken"),
                      }}
                      onChange={handleChangeUserPic}
                    >
                      {profilePicUrl ? (
                        <img
                          src={profilePicUrl}
                          alt="avatar"
                          style={{
                            width: "85%",
                            height: "85%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </>
              </div>
              {/* {profilePicUrl && (
            <Image
              src={profilePicUrl}
              alt="file"
              preview={false}
              width={200}
              style={{ marginTop: "10px" }}
            />
          )} */}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item normalize={(value) => value.trim()}>
              <Button
                loading={updateProfileLoader}
                align="center"
                htmlType="submit"
                className="primary"
                size="large"
                style={{ width: 200, marginTop: "30px" }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };
  const UpdateBusinessProfile = () => {
    return (
      <Form
        initialValues={{ country_phone_code: "+91" }}
        autoComplete="off"
        requiredMark={true}
        form={businessForm}
        layout="vertical"
        size="large"
        onFinish={onFinishBusiness}
        onFinishFailed={onFinishFailedBusiness}
      >
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <Form.Item
              colon={false}
              label="Business Name"
              name="business_name"
              // normalize={(value) =>
              //   value.replace(/[^a-zA-Z]/g, "").trim()
              // }
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
                {
                  validator: (_, value) =>
                    value && value.length <= 15
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please enter maximum 15 characters for Business Name"
                          )
                        ),
                },
              ]}
            >
              <Input
                autoFocus="none"
                type={"text"}
                placeholder="Enter Business Name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Business Email"
              normalize={(value) => value.trim()}
              colon={false}
              name="business_email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input autoFocus="none" type={"text"} placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <p style={{ color: "transparent" }}>transparent </p> */}
            <Form.Item
              normalize={(value) => value.trim()}
              colon={false}
              name="business_mobile"
              label="Business Mobile no"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && value.length == 10) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Invalid Mobile Number!"));
                  },
                }),
              ]}
            >
              <Input
                maxLength={2}
                autoFocus="none"
                type={"number"}
                placeholder="Enter Business Mobile Number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              label="Office Phone no"
              colon={false}
              name="business_office_phone"
              rules={[
                {
                  required: true,
                  message: "Please enter Office No!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && value.length == 10) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Invalid Mobile Number!"));
                  },
                }),
              ]}
            >
              <Input type={"number"} placeholder="Enter Office Mob no." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              label="Business Website"
              colon={false}
              name="business_website"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.trim() === "") {
                      // If no value is added, return success
                      return Promise.resolve();
                    }
                    if (
                      /^(https?:\/\/)?[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(value)
                    ) {
                      // If value is a valid URL, return success
                      return Promise.resolve();
                    }
                    // If value is not a valid URL, return error message
                    return Promise.reject(new Error("Invalid Website URL"));
                  },
                }),
              ]}
            >
              <Input placeholder="Enter Website" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Broker Name"
              colon={false}
              name="broker_name"
              rules={[
                {
                  required: false,
                  message: "Please enter Broker Name!",
                },
              ]}
            >
              <Input placeholder="Enter Broker Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Business Address"
              colon={false}
              name="business_address"
              rules={[
                {
                  required: true,
                  message: "Please enter Business address!",
                },
              ]}
            >
              {/* <Input.Password placeholder="Enter Business Address" /> */}
              <TextArea
                style={{
                  height: 100,
                  resize: "none",
                }}
                placeholder="Business Address"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              normalize={(value) => value.trim()}
              name="business_logo"
              label="Business Logo"
              rules={[
                {
                  required: false,
                  message: "Please select image!",
                },
              ]}
            >
              <div>
                <>
                  <ImgCrop rotationSlider aspect={4 / 3} fillColor="white">
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      headers={{
                        Authorization:
                          "Bearer " +
                          localStorage.getItem("scoutheimAdminToken"),
                      }}
                      onChange={handleChangeBusinessUserPic}
                    >
                      {businessProfilePicUrl ? (
                        <img
                          src={businessProfilePicUrl}
                          alt="avatar"
                          style={{
                            width: "85%",
                            height: "85%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </>
              </div>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item normalize={(value) => value.trim()}>
              <Button
                loading={updateBusinessLoader}
                align="center"
                htmlType="submit"
                className="primary"
                size="large"
                style={{ width: 200, marginTop: "30px" }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Fragment>
      <Spin spinning={updateProfileLoader}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              className="form-card-border business-u-card"
              title={
                userProfileData &&
                userProfileData.roles !== "admin" &&
                userProfileData.live_business ? (
                  <div className="flex">
                    <h3
                      style={{ letterSpacing: 1 }}
                      className={`${!isBusiness && "line-bottom"}`}
                      onClick={() => setIsBusiness(false)}
                    >
                      Update Profile
                    </h3>
                    <h3
                      style={{ letterSpacing: 1 }}
                      className={`${isBusiness && "line-bottom"}`}
                      onClick={() => setIsBusiness(true)}
                    >
                      Update Business
                    </h3>
                  </div>
                ) : (
                  <h3 style={{ cursor: "default", letterSpacing: 1 }}>
                    Update Profile
                  </h3>
                )
              }
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {isBusiness ? <UpdateBusinessProfile /> : <UpdateProfile />}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    updateProfileLoader: state.auth.updateProfileLoader,
    updateBusinessLoader: state.business.updateBusinessLoader,
  };
};

export default connect(mapStateToProps, {
  actionUpdateBusiness,
  actionUpdateUserProfile,
})(UpdateProfile);
