import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  actionDeleteProperty,
  actionGetPropertyDetail,
  actionAddOwner,
  actionGetLeads,
  actionDeleteOwner,
  actionGetPropertyOpenHouseDetail,
  actionGetSingleLead,
} from "../../store/actions/propertyAction";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  Descriptions,
  Image,
  Popconfirm,
  Row,
  Spin,
  Upload,
  Modal,
  List,
  Input,
  Typography,
  Tag,
  Empty,
  Space,
  Table,
} from "antd";
import email from "../../assets/img/icon/email.svg";
import profile from "../../assets/img/icon/profile.svg";
import telephone from "../../assets/img/icon/telephone.svg";
import image from "../../assets/img/dashboard/view-property.png";
import animities_icon from "../../assets/img/icon/animities_icon.svg";
import {
  DeleteOutlined,
  PlayCircleOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const ViewProperty = (props) => {
  const {
    actionDeleteProperty,
    deletePropertyLoader,
    actionDeleteOwner,
    actionGetPropertyDetail,
    propertyDetailData,
    propertyDetailLoader,
    actionAddOwner,
    addOnwerLoader,
    deleteOnwerLoader,
    actionGetPropertyOpenHouseDetail,
    propertyOpenHouseDetailLoader,
    propertyOpenHouseDetailData,
    actionGetLeads,
    leadsLoader,
    leadsData,
    actionGetSingleLead,
    singleLeadsData,
    singleLeadLoader,
  } = props;
  const { propertyId } = useParams();

  const [modal2Open, setModal2Open] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState(false);
  const [leadId, setLeadId] = useState("");

  useEffect(() => {
    if (propertyId) {
      actionGetPropertyDetail(propertyId);
      actionGetPropertyOpenHouseDetail(propertyId);
      actionGetLeads(propertyId);
    }
  }, [propertyId]);

  const onFinish = (values) => {
    const request = {
      ...values,
    };
    actionAddOwner(request, propertyId, form, setModal2Open);
  };

  const tableData = [];
  leadsData &&
    leadsData.length > 0 &&
    leadsData.map((lead, index) => {
      tableData.push({
        key: index,
        _id: lead._id,
        property: lead._id,
        name: lead.first_name + " " + lead.last_name,
        email: lead.email,
        phone: lead.phone,
        question_answers: lead.question_answers,
        lead_type: lead.lead_type,
      });
    });

  const deleteOwner = (id) => {
    console.log("Deleting owner with ID:", id);
    actionDeleteOwner(propertyId, id);
  };

  const data = [
    { label: "Street Address", value: propertyDetailData.street_address },
    { label: "Country", value: propertyDetailData.country },
    { label: "State", value: propertyDetailData.state },
    { label: "City", value: propertyDetailData.city },
    { label: "Zip Code", value: propertyDetailData.zip_code },
    { label: "Home Type", value: propertyDetailData.home_type },
    { label: "Source", value: propertyDetailData.source },
    { label: "Asking Price", value: propertyDetailData.asking_price },
    { label: "Estimated Taxes", value: propertyDetailData.estimated_taxes },
    {
      label: "Estimated Value",
      value: ` ${
        propertyDetailData.estimated_value
          ? "Low : " + propertyDetailData.estimated_value.low + " , "
          : "  "
      }${
        propertyDetailData.estimated_value
          ? "Estimated : " +
            propertyDetailData.estimated_value.estimated +
            " ,  "
          : ""
      }${
        propertyDetailData.estimated_value
          ? "High : " + propertyDetailData.estimated_value.high + "  "
          : ""
      }`,
    },
    {
      label: "Rental Value",
      value: ` ${
        propertyDetailData.rental_analysis
          ? "Low : " + propertyDetailData.rental_analysis.low + " , "
          : "  "
      }${
        propertyDetailData.rental_analysis
          ? "Rent : " + propertyDetailData.rental_analysis.rent + " ,  "
          : ""
      }${
        propertyDetailData.rental_analysis
          ? "High : " + propertyDetailData.rental_analysis.high + "  "
          : ""
      }`,
    },
    {
      label: "Estimated Mortgage",
      value: propertyDetailData.estimated_mortgage,
    },
    { label: "Unit No", value: propertyDetailData.unit_no },
    { label: "Beds", value: propertyDetailData.beds },
    { label: "Baths", value: propertyDetailData.baths },
    {
      label: "Finished square feet",
      value: propertyDetailData.finished_square_feet,
    },
    { label: "Lot Size", value: propertyDetailData.lot_size },
    { label: "Year built", value: propertyDetailData.year_built },
    // { label: "Structural remodel year", value: propertyDetailData.st },
    { label: "HOA dues", value: propertyDetailData.hoa_dues },
    {
      label: "Basement sq. ft.",
      value: propertyDetailData.basement_square_feet,
    },
    { label: "Garage sq. ft.", value: propertyDetailData.garage_square_feet },
    { label: "Construction", value: propertyDetailData.construction },
    { label: "Fireplaces", value: propertyDetailData.fireplaces },
    { label: "Status", value: propertyDetailData.status },
    { label: "Garage sq. ft.", value: propertyDetailData.garage_square_feet },
    { label: "Describe the Property", value: propertyDetailData.description },
    {
      label: "Describe the neighborhood",
      value: propertyDetailData.describe_the_neighbourhood,
    },
  ];

  const confirm = (id) => {
    actionDeleteProperty(id);
  };

  const videoData = [];
  propertyDetailData.property_images &&
    propertyDetailData.property_images.map((url, index) => {
      if (url.endsWith(".mp4")) {
        videoData.push({
          url,
          uid: index,
          name: url,
          thumbUrl: url && url.replace(".mp4", "-thumb.png"),
        });
      }
    });

  const ImageData =
    propertyDetailData.property_images &&
    propertyDetailData.property_images.filter((url) => !url.endsWith(".mp4"));

  // console.log({ videoData, ImageData });

  const getStatusTagColor = (status) => {
    // Define color mapping based on status values
    const colorMap = {
      "For Sale": "#f7a21c",
      "For Rent": "#e94894",
      Sold: "#51b3fa",
    };
    return colorMap[status];
  };

  const handleOpenLeadModal = (id) => {
    setLeadId(id);
    setIsModalOpen(true);
    actionGetSingleLead(id, propertyId);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      align: "center",
    },
    {
      ellipsis: true,
      align: "center",
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <a onClick={() => handleOpenLeadModal(record._id)}>View</a>
          </Space>
        );
      },
    },
  ];
  const Data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      email: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];

  // Close Modal
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Spin spinning={propertyDetailLoader}>
        <div className="view-property">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li"
                    align="middle"
                    title="Property Image"
                    bordered={false}
                    style={{ width: "100%" }}
                  >
                    <Image.PreviewGroup items={ImageData}>
                      <Image width={300} height={250} style={{objectFit:'contain'}} src={ImageData && ImageData[0]} />
                    </Image.PreviewGroup>
                  </Card>
                </Col>
                {videoData && videoData.length > 0 ? (
                  <Col span={24}>
                    <Card
                      className="form-card-border list-layout-li"
                      align="middle"
                      title="Property Video"
                      bordered={false}
                      style={{ width: "100%" }}
                    >
                      <Image.PreviewGroup items={videoData}>
                        {/* Render your video items */}
                      </Image.PreviewGroup>
                      <Upload
                        listType="picture-card"
                        fileList={videoData}
                        showUploadList={{
                          previewIcon: (
                            <PlayCircleOutlined
                              color="#fffff"
                              style={{ color: "#ffffff", fontSize: "20px" }}
                            />
                          ),
                        }}
                        disabled
                      />
                    </Card>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Card
                      className="form-card-border list-layout-li"
                      align="middle"
                      title="Properties Video"
                      bordered={false}
                      style={{ width: "100%" }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Card>
                  </Col>
                )}
                {/* open house listing */}

                {propertyOpenHouseDetailData &&
                propertyOpenHouseDetailData.length > 0 ? (
                  <Col span={24}>
                    <Card
                      className="form-card-border list-layout-li favourite-property-card"
                      align="middle"
                      title="Open House"
                      bordered={false}
                      style={{ width: "100%", padding: "0px" }}
                    >
                      <List
                        style={{
                          maxHeight: 400,
                          height: "auto",
                          overflow: "auto",
                          padding: "0px",
                        }}
                        className="scrollable-list"
                        itemLayout="horizontal"
                        dataSource={propertyOpenHouseDetailData}
                        renderItem={(item, index) => {
                          const tagColor = item.is_open_house_active_now
                            ? "#FF9A3E"
                            : "#443527";
                          const tagText = item.is_open_house_active_now
                            ? "Open"
                            : "Close";
                          return (
                            <List.Item
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                background:
                                  index % 2 === 0 ? "#F8F8F8" : "white",
                                // padding: '16px',
                                // border: '1px solid #ddd',
                                // borderRadius: '8px',
                                // marginBottom: '16px',
                              }}
                            >
                              <div style={{ marginRight: "16px" }}>
                                <Typography.Title
                                  level={5}
                                  style={{ color: "#443527", marginTop: "0px" }}
                                >
                                  Start Date & Time
                                </Typography.Title>
                                <Typography style={{ color: "#443527" }}>
                                  {/* <CheckCircleOutlined style={{ marginRight: '8px' }} /> */}
                                  {dayjs(item.start_date_time).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                                <Typography
                                  style={{ color: "#443527", marginTop: "2px" }}
                                >
                                  {dayjs(item.start_date_time).format(
                                    "HH:mm:ss"
                                  )}
                                </Typography>
                              </div>
                              <div style={{ marginRight: "16px" }}>
                                <Typography.Title
                                  level={5}
                                  style={{ color: "#443527", marginTop: "0px" }}
                                >
                                  End Date & Time
                                </Typography.Title>
                                <Typography style={{ color: "#443527" }}>
                                  {/* <CloseCircleOutlined style={{ marginRight: '8px' }} /> */}
                                  {dayjs(item.end_date_time).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                                <Typography
                                  style={{ color: "#443527", marginTop: "2px" }}
                                >
                                  {dayjs(item.end_date_time).format("HH:mm:ss")}
                                </Typography>
                              </div>
                              <div>
                                <Typography.Title
                                  level={5}
                                  style={{ color: "#443527", marginTop: "0px" }}
                                >
                                  Status
                                </Typography.Title>
                                <Typography>
                                  <Tag
                                    color={tagColor}
                                    style={{ fontSize: "14px", color: "white" }}
                                  >
                                    {tagText}
                                  </Tag>
                                </Typography>
                                <Typography style={{ marginTop: "2px" }}>
                                  <Tag
                                    color={getStatusTagColor(
                                      propertyDetailData.status
                                    )}
                                    style={{ color: "white", fontSize: "14px" }}
                                  >
                                    {propertyDetailData.status}
                                  </Tag>
                                </Typography>
                              </div>
                            </List.Item>
                          );
                        }}
                      />
                    </Card>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Card
                      className="form-card-border list-layout-li favourite-property-card"
                      align="middle"
                      title="Open House"
                      bordered={false}
                      style={{ width: "100%" }}
                    >
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={{ padding: "24px" }}
                      />
                    </Card>
                  </Col>
                )}

                {/* Leads Listing */}
                <Col span={24}>
                  <Card
                    className="scrollable-list form-card-border list-layout-li favourite-property-card"
                    align="middle"
                    title={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Leads
                      </span>
                    }
                    bordered={false}
                    style={{ width: "100%", padding: "0px" }}
                  >
                    <Table
                      showHeader={tableData && tableData.length !== 0}
                      className="table-scrollable-list"
                      scroll={{
                        x: "max-content",
                        y: 300,
                      }}
                      pagination={false}
                      sticky={false}
                      tableLayout="auto"
                      columns={columns}
                      dataSource={tableData}
                    />
                  </Card>
                </Col>

                {/* Owners Listing  */}
                <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li favourite-property-card"
                    align="middle"
                    title={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Owners
                        <Button
                          className="owner-delete-button"
                          onClick={() => setModal2Open(true)}
                        >
                          Add Owner
                        </Button>
                      </span>
                    }
                    bordered={false}
                    style={{ width: "100%", padding: "0px" }}
                  >
                    <List
                      style={{
                        maxHeight: 400,
                        height: "auto",
                        overflow: "auto",
                      }}
                      className="scrollable-list"
                      itemLayout="horizontal"
                      dataSource={propertyDetailData.owners}
                      renderItem={(owner, index) => (
                        <List.Item
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>
                            <span>
                              <img
                                src={profile}
                                style={{ marginRight: "3px" }}
                              />
                              {`${owner.first_name} ${owner.last_name}`}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img src={email} style={{ marginRight: "3px" }} />
                              {owner.email}
                            </span>
                            {/* <br /> */}
                            <span
                              style={{
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={telephone}
                                style={{ marginRight: "3px" }}
                              />
                              {owner.phone}
                            </span>
                          </Typography>
                          <Button
                            className="owner-delete-button"
                            onClick={() => deleteOwner(owner._id)}
                            loading={owner.isLoading}
                          >
                            Delete
                          </Button>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <Card
                className="body-padding form-card-border list-layout-li"
                title="More Details"
                bordered={false}
                style={{ width: "100%" }}
              >
                <Descriptions
                  title=""
                  layout="horizontal"
                  colon={true}
                  bordered
                  column={1}
                >
                  {data?.map((item, index) => (
                    <Descriptions.Item
                      key={index}
                      label={item.label}
                      style={{
                        background:
                          index % 2 !== 0 ? "rgba(239, 239, 239, 1)" : "",
                      }} // Set color dynamically or default to black
                    >
                      {item.value ? item.value : "-"}
                    </Descriptions.Item>
                  ))}
                  <Descriptions.Item
                    label="Additional Information"
                    style={{
                      borderRight: "none",
                      background: "rgba(239, 239, 239, 1)",
                      color: "#443527",
                    }}
                  />
                  <Descriptions.Item label="Room Details">
                    {propertyDetailData &&
                      (propertyDetailData.applinces &&
                      propertyDetailData.applinces.length === 0 &&
                      propertyDetailData.basement &&
                      propertyDetailData.basement.length === 0 &&
                      propertyDetailData.floor_covering &&
                      propertyDetailData.floor_covering.length === 0 &&
                      propertyDetailData.rooms &&
                      propertyDetailData.rooms.length === 0 &&
                      propertyDetailData.indoor_features &&
                      propertyDetailData.indoor_features.length === 0 ? (
                        "-"
                      ) : (
                        <Row>
                          {/* <Col span={6}>
                          {propertyDetailData &&
                            propertyDetailData.applinces &&
                            propertyDetailData.applinces.length > 0 &&
                            propertyDetailData.applinces.map((e, index) => (
                              <div key={index}>{e}</div>
                            ))}
                        </Col> */}
                          {propertyDetailData &&
                            propertyDetailData.applinces &&
                            propertyDetailData.applinces.length > 0 && (
                              <Col span={6}>
                                <div>
                                  <strong>Applinces:</strong>
                                  {propertyDetailData.applinces.map(
                                    (e, index) => (
                                      <div key={index}>
                                        <img
                                          src={animities_icon}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {e}
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                            )}
                          {propertyDetailData &&
                            propertyDetailData.basement &&
                            propertyDetailData.basement.length > 0 && (
                              <Col span={6}>
                                <div>
                                  <strong>Basement:</strong>
                                  {propertyDetailData.basement.map(
                                    (e, index) => (
                                      <div key={index}>
                                        <img
                                          src={animities_icon}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {e}
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                            )}
                          {propertyDetailData &&
                            propertyDetailData.floor_covering &&
                            propertyDetailData.floor_covering.length > 0 && (
                              <Col span={6}>
                                <div>
                                  <strong>Floor Covering:</strong>
                                  {propertyDetailData.floor_covering.map(
                                    (e, index) => (
                                      <div key={index}>
                                        <img
                                          src={animities_icon}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {e}
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                            )}
                          {propertyDetailData &&
                            propertyDetailData.rooms &&
                            propertyDetailData.rooms.length > 0 && (
                              <Col span={6}>
                                <div>
                                  <strong>Rooms:</strong>
                                  {propertyDetailData.rooms.map((e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            )}
                          {propertyDetailData &&
                            propertyDetailData.indoor_features &&
                            propertyDetailData.indoor_features.length > 0 && (
                              <Col span={6}>
                                <div>
                                  <strong>Indoor Features:</strong>
                                  {propertyDetailData.indoor_features.map(
                                    (e, index) => (
                                      <div key={index}>
                                        <img
                                          src={animities_icon}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {e}
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                            )}
                        </Row>
                      ))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Building Details"
                    style={{ background: "rgba(239, 239, 239, 1)" }}
                  >
                    {propertyDetailData.building_amenities &&
                    propertyDetailData.building_amenities.length === 0 &&
                    propertyDetailData.architectural_style &&
                    propertyDetailData.architectural_style.length === 0 &&
                    propertyDetailData.exterior &&
                    propertyDetailData.exterior.length === 0 &&
                    propertyDetailData.outdoor_amenities &&
                    propertyDetailData.outdoor_amenities.length === 0 &&
                    propertyDetailData.parking &&
                    propertyDetailData.parking.length === 0 &&
                    propertyDetailData.roof &&
                    propertyDetailData.roof.length === 0 &&
                    propertyDetailData.view &&
                    propertyDetailData.view.length === 0 &&
                    propertyDetailData.parking &&
                    propertyDetailData.parking.length === 0 ? (
                      "-"
                    ) : (
                      <Row>
                        {propertyDetailData.building_amenities &&
                          propertyDetailData.building_amenities.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Building Amenities:</strong>
                                {propertyDetailData.building_amenities.map(
                                  (e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.architectural_style &&
                          propertyDetailData.architectural_style.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Architectural Style:</strong>
                                {propertyDetailData.architectural_style.map(
                                  (e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.exterior &&
                          propertyDetailData.exterior.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Exterior:</strong>
                                {propertyDetailData.exterior.map((e, index) => (
                                  <div key={index}>
                                    <img
                                      src={animities_icon}
                                      style={{ marginRight: "3px" }}
                                    />
                                    {e}
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.outdoor_amenities &&
                          propertyDetailData.outdoor_amenities.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Outdoor Amenities:</strong>
                                {propertyDetailData.outdoor_amenities.map(
                                  (e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.parking &&
                          propertyDetailData.parking.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Parking:</strong>
                                {propertyDetailData.parking.map((e, index) => (
                                  <div key={index}>
                                    <img
                                      src={animities_icon}
                                      style={{ marginRight: "3px" }}
                                    />
                                    {e}
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.roof &&
                          propertyDetailData.roof.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Roof:</strong>
                                {propertyDetailData.roof.map((e, index) => (
                                  <div key={index}>
                                    <img
                                      src={animities_icon}
                                      style={{ marginRight: "3px" }}
                                    />
                                    {e}
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.view &&
                          propertyDetailData.view.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>View:</strong>
                                {propertyDetailData.view.map((e, index) => (
                                  <div key={index}>
                                    <img
                                      src={animities_icon}
                                      style={{ marginRight: "3px" }}
                                    />
                                    {e}
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                      </Row>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Utility Details">
                    {propertyDetailData.cooling_type &&
                    propertyDetailData.cooling_type.length === 0 &&
                    propertyDetailData.heating_type &&
                    propertyDetailData.heating_type.length === 0 &&
                    propertyDetailData.parkingheating_fual &&
                    propertyDetailData.parkingheating_fual.length === 0 ? (
                      "-"
                    ) : (
                      <Row>
                        {propertyDetailData.cooling_type &&
                          propertyDetailData.cooling_type.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Cooling Type:</strong>
                                {propertyDetailData.cooling_type.map(
                                  (e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.heating_type &&
                          propertyDetailData.heating_type.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Heating Type:</strong>
                                {propertyDetailData.heating_type.map(
                                  (e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                        {propertyDetailData.parkingheating_fual &&
                          propertyDetailData.parkingheating_fual.length > 0 && (
                            <Col span={6}>
                              <div>
                                <strong>Parkingheating Fual:</strong>
                                {propertyDetailData.parkingheating_fual.map(
                                  (e, index) => (
                                    <div key={index}>
                                      <img
                                        src={animities_icon}
                                        style={{ marginRight: "3px" }}
                                      />
                                      {e}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                      </Row>
                    )}
                    <br />
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Related Website"
                    style={{ background: "rgba(239, 239, 239, 1)" }}
                  >
                    {propertyDetailData.related_website
                      ? propertyDetailData.related_website
                      : "-"}
                  </Descriptions.Item>
                </Descriptions>

                {/* <br/> */}
                <br />
                <br />
                <Link to={`/update-property/${propertyDetailData._id}`}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                  >
                    Update
                  </Button>
                </Link>
                {/* <Button
                style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                size="large"
                shape="default"
                type="danger"
              >
                Delete
              </Button> */}
                <Popconfirm
                  okButtonProps={{
                    loading: deletePropertyLoader,
                  }}
                  placement="topLeft"
                  title="Are you sure to delete this Property?"
                  onConfirm={() => confirm(propertyDetailData._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                    icon={<DeleteOutlined />}
                    type="danger"
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
      <Modal
        title="Add Owner"
        centered
        open={modal2Open}
        footer={false}
        onCancel={() => {
          form && form.resetFields(); // Reset the form fields
          setModal2Open(false);
        }}
      >
        <Form
          // onValuesChange={handleFormChange}
          autoComplete="off"
          requiredMark={true}
          form={form}
          layout="vertical"
          size="medium"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Row gutter={[32, 0]}>
            <Col span={12}>
              <Form.Item
                style={{ padding: "0 !important" }}
                colon={false}
                label="First Name"
                name="first_name"
                normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
                rules={[
                  {
                    required: true,
                    message: "Please input owner's first name!",
                  },
                  // {
                  //   validator: (_, value) =>
                  //     value && value.length <= 15
                  //       ? Promise.resolve()
                  //       : Promise.reject(
                  //         new Error(
                  //           "Please enter maximum 15 characters for first Name"
                  //         )
                  //       ),
                  // },
                ]}
              >
                <Input
                  // autoFocus="none"
                  type={"text"}
                  placeholder="Enter First Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                colon={false}
                name="last_name"
                normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
                rules={[
                  {
                    required: true,
                    message: "Please enter owner's last name!",
                  },
                  // {
                  //   validator: (_, value) =>
                  //     value && value.length <= 15
                  //       ? Promise.resolve()
                  //       : Promise.reject(
                  //         new Error(
                  //           "Please enter maximum 15 characters for last Name"
                  //         )
                  //       ),
                  // },
                ]}
              >
                <Input
                  // autoFocus="none"
                  type={"text"}
                  placeholder="Enter Last Name"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                normalize={(value) => value.trim()}
                colon={false}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email!",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  // autoFocus="none"
                  type={"text"}
                  placeholder="Enter Email"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                normalize={(value) => value.trim()}
                colon={false}
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone no.!",
                  },
                  // ({ getFieldValue }) => ({
                  //   validator(_, value) {
                  //     if (value && value.length == 10) {
                  //       return Promise.resolve();
                  //     }
                  //     return Promise.reject(new Error("Invalid Phone Number!"));
                  //   },
                  // }),
                ]}
              >
                <Input
                  maxLength={2}
                  // autoFocus="none"
                  type={"number"}
                  placeholder="Enter Phone Number"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                normalize={(value) => value.trim()}
                style={{ textAlign: "end" }}
              >
                <Button
                  loading={addOnwerLoader}
                  align="center"
                  htmlType="submit"
                  className="primary"
                >
                  Add Owner
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        className="lead-modal"
        title={
          <h4
            style={{
              margin: "10px 0",
              padding: "10px 25px",
              paddingLeft: 20,
              borderBottom: "0.5px solid grey",
            }}
          >
            Lead Questions
          </h4>
        }
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={singleLeadLoader}>
          <div key={singleLeadsData._id} className="lead-customer">
            {singleLeadsData.question_answers &&
            singleLeadsData.question_answers.length > 0 ? (
              singleLeadsData.question_answers.map((question, index) => {
                const key = `${singleLeadsData._id}-${index}`; // Generate unique key for each card
                return (
                  <Card className="lead-card" key={key}>
                    <p style={{ fontWeight: "bold" }}>{`Question ${
                      index + 1
                    }`}</p>
                    <p>
                      <span style={{ fontWeight: 500 }}>Question</span>:{" "}
                      {question.name}
                    </p>
                    <p>
                      <span style={{ fontWeight: 500 }}>Answer</span>:{" "}
                      {question.answer}
                    </p>
                  </Card>
                );
              })
            ) : (
              <Empty />
            )}
          </div>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    propertyDetailLoader: state.properties.propertyDetailLoader,
    propertyDetailData: state.properties.propertyDetailData,
    deletePropertyLoader: state.properties.deletePropertyLoader,
    addOnwerLoader: state.properties.addOnwerLoader,
    deleteOnwerLoader: state.properties.deleteOnwerLoader,
    propertyOpenHouseDetailLoader:
      state.properties.propertyOpenHouseDetailLoader,
    propertyOpenHouseDetailData: state.properties.propertyOpenHouseDetailData,
    leadsLoader: state.properties.leadsLoader,
    leadsData: state.properties.leadsData,
    singleLeadsData: state.properties.singleLeadsData,
    singleLeadLoader: state.properties.singleLeadLoader,
  };
};

export default connect(mapStateToProps, {
  actionDeleteProperty,
  actionGetPropertyDetail,
  actionAddOwner,
  actionDeleteOwner,
  actionGetPropertyOpenHouseDetail,
  actionGetLeads,
  actionGetSingleLead,
})(ViewProperty);
