import axios from "axios";
import * as actionTypes from "../action";
import { message } from "antd";
import { BASE_URL } from "../../components/config/web-config";

export const actionGetAllProperty =
  (
    skip,
    limit,
    search,
    sort,
    status,
    minvalue,
    maxvalue,
    beds,
    baths,
    property_type,
    min_finished_square_feet,
    max_finished_square_feet,
    min_lot_size,
    max_lot_size,
    min_year_built,
    max_year_built,
    min_no_of_stories,
    max_no_of_stories,
    hoa_dues,
    parking,
    basement,
    cooling_type,
    heating_type,
    view
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: actionTypes.GET_ALL_PROPERTIES_LOADING, payload: true });
      try {
        // let result = await axios.get(`${BASE_URL}/properties`);
        // let result = await axios.get(
        //   `${BASE_URL}/properties?${limit ? "&limit=" + limit : ""}${
        //     skip ? "&skip" + skip : ""
        //   }${search ? "&search" + search : ""}`
        // );
        let result = await axios.get(
          `${BASE_URL}/properties?limit=${limit}&skip=${skip}${search ? "&search=" + search : ""
          }${sort ? "&sort=" + sort : ""}${status ? "&status=" + status : ""}${minvalue ? "&minvalue=" + minvalue : ""
          }${maxvalue ? "&maxvalue=" + maxvalue : ""}${beds ? "&beds=" + beds : ""
          }${baths ? "&baths=" + baths : ""}${property_type ? "&property_type=" + property_type : ""
          }${min_finished_square_feet
            ? "&min_finished_square_feet=" + min_finished_square_feet
            : ""
          }${max_finished_square_feet
            ? "&max_finished_square_feet=" + max_finished_square_feet
            : ""
          }${min_lot_size ? "&min_lot_size=" + min_lot_size : ""}${max_lot_size ? "&max_lot_size=" + max_lot_size : ""
          }${min_year_built ? "&min_year_built=" + min_year_built : ""}${max_year_built ? "&max_year_built=" + max_year_built : ""
          }${min_no_of_stories ? "&min_no_of_stories=" + min_no_of_stories : ""}${max_no_of_stories ? "&max_no_of_stories=" + max_no_of_stories : ""
          }${hoa_dues ? "&hoa_dues=" + hoa_dues : ""}
        ${parking ? "&parking=" + parking : ""}
        ${basement ? "&basement=" + basement : ""}
        ${cooling_type ? "&cooling_type=" + cooling_type : ""}
        ${heating_type ? "&heating_type=" + heating_type : ""}
        ${view ? "&view=" + view : ""}`
        );
        dispatch({
          type: actionTypes.GET_ALL_PROPERTIES_LOADING,
          payload: false,
        });
        if (parseInt(result.data.status) === 200) {
          dispatch({
            type: actionTypes.GET_ALL_PROPERTIES_DATA,
            payload: result.data.data,
          });
          dispatch({
            type: actionTypes.TOTAL_PROPERTIES_COUNT,
            payload: result.data.total_records,
          });
        } else if (parseInt(result.data.status) === 404) {
          dispatch({
            type: actionTypes.GET_ALL_PROPERTIES_DATA,
            payload: [],
          });
        } else message.error(result.data.message, 5);
      } catch (error) {
        message.error(error.message, 5);
        dispatch({
          type: actionTypes.GET_ALL_PROPERTIES_LOADING,
          payload: false,
        });
      }
    };

export const actionAddProperty =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_PROPERTY_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/properties`, userData);
      dispatch({ type: actionTypes.ADD_PROPERTY_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_PROPERTY_DATA,
          payload: result.data.data,
        });
        message.success("Property Added Successfully", 5);
        navigate && setTimeout(() => navigate("/properties"), 1000);
      } else if (result.data.status === 404) {
        dispatch({ type: actionTypes.ADD_PROPERTY_DATA, payload: [] });
      } else message.error(result.data.message, 5000);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_PROPERTY_LOADING, payload: false });
    }
  };

export const actionUpdateProperty =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_PROPERTY_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/properties/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_PROPERTY_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success("Property Updated Successfully", 5);
        navigate && setTimeout(() => navigate("/properties"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_PROPERTY_LOADING, payload: false });
    }
  };

export const actionDeleteProperty = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DELETE_PROPERTY_LOADING, payload: true });
  try {
    let result = await axios.delete(`${BASE_URL}/properties/${id}`);
    dispatch({ type: actionTypes.DELETE_PROPERTY_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_PROPERTY_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.DELETE_PROPERTY_LOADING, payload: false });
  }
};

export const actionGetPropertyDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_PROPERTY_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/properties/${id}`);
    dispatch({ type: actionTypes.GET_PROPERTY_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_PROPERTY_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_PROPERTY_DETAIL_LOADING, payload: false });
  }
};

export const actionGetCountry = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_COUNTRY_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/location/get-all-countries`);
    dispatch({ type: actionTypes.GET_COUNTRY_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_COUNTRY_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_COUNTRY_LOADING, payload: false });
  }
};

export const actionGetStates = (countryCode) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_STATES_LOADING, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/location/get-states-of-country/${countryCode}`
    );
    dispatch({ type: actionTypes.GET_STATES_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_STATES_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_STATES_LOADING, payload: false });
  }
};

export const actionGetCities =
  (countryCode, stateCode) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_CITIES_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/location/get-cities-of-states/${countryCode}/${stateCode}`
      );
      dispatch({ type: actionTypes.GET_CITIES_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_CITIES_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_CITIES_LOADING, payload: false });
    }
  };


export const actionGetDashboardData =
  (start_date, end_date) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/properties/dashboard`);
      dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_DASHBOARD_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: false });
    }
  };

export const actionGetPropertyDashboardData =
  () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_PROPERTY_DASHBOARD_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/properties/propertydashboard`);
      dispatch({ type: actionTypes.GET_PROPERTY_DASHBOARD_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_PROPERTY_DASHBOARD_DATA,
          payload: result.data.data,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_PROPERTY_DASHBOARD_DATA,
          payload: [],
        });
      }
      else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_PROPERTY_DASHBOARD_LOADING, payload: false });
    }
  };

export const actionAddOwner =
  (userData, id, form, setModal2Open) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_OWNER_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/properties/owners/${id}`, userData);
      dispatch({ type: actionTypes.ADD_OWNER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_OWNER_DATA,
          payload: result.data.data,
        });
        message.success("Owner Added Successfully", 5);
        setModal2Open && setModal2Open(false);
        form && form.resetFields();
      } else if (result.data.status === 404) {
        dispatch({ type: actionTypes.ADD_OWNER_DATA, payload: [] });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_OWNER_LOADING, payload: false });
    }
  };


export const actionDeleteOwner = (id, ownerId) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DELETE_OWNER_LOADING, payload: { status: true, id: ownerId } });
  try {
    let result = await axios.delete(`${BASE_URL}/properties/owners/${id}/${ownerId}`);
    dispatch({ type: actionTypes.DELETE_OWNER_LOADING, payload: { status: false, id } });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_OWNER_ID, payload: ownerId });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.DELETE_OWNER_LOADING, payload: false });
  }
};


export const actionGetPropertyOpenHouseDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/properties/host-open-house/${id}`);
    dispatch({ type: actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_DATA,
        payload: result.data.data,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_DATA,
        payload: [],
      });
    }
    else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_LOADING, payload: false });
  }
};


export const actionGetDealDashboardData =
  (start_date, end_date) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_DEALS_DASHBOARD_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/dod/getdashboard`);
      dispatch({ type: actionTypes.GET_DEALS_DASHBOARD_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_DEALS_DASHBOARD_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_DEALS_DASHBOARD_LOADING, payload: false });
    }
  };
export const actionGetLeads =
  (id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_LEADS_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/lead/${id}`);
      dispatch({ type: actionTypes.GET_LEADS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_LEADS_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_LEADS_LOADING, payload: false });
    }
  };
export const actionGetSingleLead =
  (id, propertyId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_SINGLE_LEADS_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/lead/getone/${id}/${propertyId}`);
      dispatch({ type: actionTypes.GET_SINGLE_LEADS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_SINGLE_LEAD_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_SINGLE_LEADS_LOADING, payload: false });
    }
  };