import * as actionTypes from "../action";

const initialState = {
  propertiesLoader: false,
  propertiesData: [],
  addPropertiesLoader: false,
  updatePropertyLoader: false,
  addPropertiesData: [],
  propertyDetailLoader: false,
  propertyDetailData: {},
  countryLoader: false,
  countryData: [],
  stateLoader: false,
  stateData: [],
  cityLoader: false,
  cityData: [],
  propertiesCount: {},
  dashboardLoader: false,
  dashboardData: {},
  dealsDashboardLoader: {},
  dealsDashboardData: {},
  propertyDashboardLoader: false,
  propertyDashboardData: {},
  addOnwerLoader: false,
  deleteOnwerLoader: false,
  propertyOpenHouseDetailLoader: false,
  propertyOpenHouseDetailData: {},
  leadsData: [],
  leadsLoader: false,
  singleLeadsData: {},
  singleLeadLoader: false,
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_PROPERTIES_COUNT:
      return { ...state, propertiesCount: action.payload };

    case actionTypes.GET_ALL_PROPERTIES_LOADING: {
      return { ...state, propertiesLoader: action.payload };
    }
    case actionTypes.GET_ALL_PROPERTIES_DATA: {
      return { ...state, propertiesData: action.payload };
    }

    case actionTypes.GET_LEADS_LOADING: {
      return { ...state, leadsLoader: action.payload };
    }
    case actionTypes.GET_LEADS_DATA: {
      return { ...state, leadsData: action.payload };
    }

    case actionTypes.GET_SINGLE_LEAD_DATA: {
      return { ...state, singleLeadsData: action.payload };
    }
    case actionTypes.GET_SINGLE_LEADS_LOADING: {
      return { ...state, singleLeadLoader: action.payload };
    }
    case actionTypes.ADD_PROPERTY_LOADING: {
      return { ...state, addPropertiesLoader: action.payload };
    }
    case actionTypes.ADD_PROPERTY_DATA: {
      return { ...state, addPropertiesData: action.payload };
    }
    case actionTypes.UPDATE_PROPERTY_LOADING:
      return { ...state, updatePropertyLoader: action.payload };

    case actionTypes.DELETE_PROPERTY_LOADING:
      return { ...state, deletePropertyLoader: action.payload };

    case actionTypes.DELETE_PROPERTY_ID:
      return {
        ...state,
        propertiesData: [
          ...state.propertiesData.filter((data) => data._id !== action.payload),
        ],
      };

    case actionTypes.GET_PROPERTY_DETAIL_LOADING:
      return { ...state, propertyDetailLoader: action.payload };

    case actionTypes.GET_PROPERTY_DETAIL_DATA:
      return {
        ...state,
        propertyDetailData:
          action.payload,

      };

    case actionTypes.GET_COUNTRY_LOADING: {
      return { ...state, countryLoader: action.payload };
    }
    case actionTypes.GET_COUNTRY_DATA: {
      return { ...state, countryData: action.payload };
    }

    case actionTypes.GET_STATES_LOADING: {
      return { ...state, stateLoader: action.payload };
    }
    case actionTypes.GET_STATES_DATA: {
      return { ...state, stateData: action.payload };
    }

    case actionTypes.GET_CITIES_LOADING: {
      return { ...state, cityLoader: action.payload };
    }
    case actionTypes.GET_CITIES_DATA: {
      return { ...state, cityData: action.payload };
    }
    case actionTypes.GET_DASHBOARD_LOADING:
      return { ...state, dashboardLoader: action.payload };

    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case actionTypes.GET_DEALS_DASHBOARD_LOADING:
      return { ...state, dealsDashboardLoader: action.payload };

    case actionTypes.GET_DEALS_DASHBOARD_DATA:
      return {
        ...state,
        dealsDashboardData: action.payload,
      };

    case actionTypes.GET_PROPERTY_DASHBOARD_LOADING:
      return { ...state, propertyDashboardLoader: action.payload };

    case actionTypes.GET_PROPERTY_DASHBOARD_DATA:
      return {
        ...state,
        propertyDashboardData: action.payload,
      };

    case actionTypes.ADD_OWNER_DATA: {
      const currentOwners = state.propertyDetailData.owners || [];
      const updatedOwners = [...currentOwners, action.payload];

      return {
        ...state,
        propertyDetailData: {
          ...state.propertyDetailData,
          owners: updatedOwners,
        },
      };
    }


    case actionTypes.ADD_OWNER_LOADING: {
      return { ...state, addOnwerLoader: action.payload };
    }
    case actionTypes.DELETE_OWNER_LOADING:
      return {
        ...state,
        propertyDetailData: {
          ...state.propertyDetailData,
          owners: state.propertyDetailData.owners && state.propertyDetailData.owners.map((o) => {
            return o._id == action.payload.id ? { ...o, isLoading: true } : { ...o };
          })
        }
      };

    case actionTypes.DELETE_OWNER_ID: {
      const updatedOwners = state.propertyDetailData.owners.filter((data) => data._id !== action.payload);

      return {
        ...state,
        propertyDetailData: {
          ...state.propertyDetailData,
          owners: updatedOwners,
        },
      };
    }

    case actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_LOADING:
      return { ...state, propertyOpenHouseDetailLoader: action.payload };

    case actionTypes.GET_PROPERTY_OPEN_HOUSE_DETAIL_DATA: {
      return { ...state, propertyOpenHouseDetailData: action.payload };
    }
    default:
      return state;
  }
};

export default propertyReducer;
