import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import image1 from "../../assets/img/dashboard/view-property.png";
import {
  Button,
  Card,
  Col,
  Image,
  Descriptions,
  List,
  Popconfirm,
  Row,
  Spin,
  Typography,
  Avatar,
  Tag,
  Divider,
  message,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams, Link } from "react-router-dom";
import logo from "../../assets/img/main-wrapper/user.jpg";
import monthlyplan from "../../assets/img/icon/monthly-plan.svg";
import cancelsub from "../../assets/img/icon/cancel-subscription.svg";
import rightupicon from "../../assets/img/icon/right-plan.svg";
import packexpiry from "../../assets/img/icon/pack-expiry.svg";
import billamount from "../../assets/img/icon/billamount.svg";
import billinghistory from "../../assets/img/icon/billing-history.svg";
import bill from "../../assets/img/icon/bill.svg";
import payment from "../../assets/img/icon/payment.svg";
import verifiedIcon from "../../assets/img/icon/verify.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  actionGetUserDetail,
  actionDeleteUser,
  actionGetAllBillingHistory,
} from "../../store/actions/userAction";
import { actionGetFavouriteProperty } from "../../store/actions/favouritePropertyAction";
import user from "../../assets/img/main-wrapper/user.jpg";
import { removeUnderScore } from "../../common/function";

const { Title, Text } = Typography;

const UserDetail = (props) => {
  const Navigate = useNavigate();

  const {
    actionGetUserDetail,
    userDetailData,
    userDetailLoader,
    actionDeleteUser,
    deleteUserLoader,
    actionGetFavouriteProperty,
    favoritePropertyData,
    actionGetAllBillingHistory,

    billingHistoryData,
    isLoading,
    userDetailDataSubscription,
  } = props;
  const { userId } = useParams();
  const [selectItem, setSelectItem] = useState("active-plan");

  useEffect(() => {
    actionGetUserDetail(userId);
    actionGetFavouriteProperty(userId, 20, 0);
  }, []);

  useEffect(() => {
    if (userId) {
      actionGetAllBillingHistory(userId);
    }
  }, [userId]);

  const confirm = (id) => {
    actionDeleteUser(id);
  };

  let icon =
    userDetailData.roles === "user" ||
    userDetailData.roles === "admin" ||
    userDetailData.roles === "agent" ? (
      <img
        src={verifiedIcon}
        className=""
        width={18}
        style={{ marginLeft: "8px" }}
      />
    ) : (
      ""
    );

  const data = [
    // { label: "Name", value: userDetailData.first_name + " " + last_name },
    {
      label: "Name",
      value: userDetailData.first_name + " " + userDetailData.last_name,
    },
    { label: "Email", value: userDetailData.email },
    {
      label: "Gender",
      value:
        userDetailData.gender === "M"
          ? "Male"
          : userDetailData.gender === "F"
          ? "Female"
          : userDetailData.gender === "O"
          ? "Other"
          : "-",
    },
    { label: "Mobile", value: userDetailData.mobile },
    // { label: "Date of Birth", value: userDetailData.dob },
    {
      label: "Roles",
      value: (
        <span style={{ display: "flex", alignItems: "center" }}>
          {userDetailData.roles && removeUnderScore(userDetailData.roles)}
          {icon}
        </span>
      ),
    },
    {
      label: "Source",
      value: userDetailData.hear_us_from ? userDetailData.hear_us_from : "-",
    },
  ];

  const items = [
    {
      label: "Active Plan",
      key: "active-plan",
    },
    {
      label: "Billing History",
      key: "billing-history",
    },
  ];

  const calculateDaysFromCurrentToEnd = (endDate) => {
    const current = dayjs();
    const end = dayjs(endDate, "YYYY-MM-DD");
    const remainingDays = end.diff(current, "days");

    return remainingDays;
  };

  const showSubscriptionTab = () => {
    switch (selectItem) {
      case "active-plan":
        return (
          <>
            {
              <>
                {userDetailData &&
                userDetailData.subscription &&
                userDetailData.subscription.subscription_plan &&
                userDetailData.subscription.subscription_plan.title ? (
                  <>
                    <div className="subscription-plan">
                      <div className="subscription-left">
                        <div className="plan-title">
                          <img
                            src={monthlyplan}
                            alt="Monthly Plan Icon"
                            className="plan-icon"
                          />
                          <span className="plan-name">
                            {
                              userDetailData?.subscription?.subscription_plan
                                ?.title
                            }{" "}
                            Plan
                          </span>
                          <Tag className="plan-status">
                            {userDetailData?.subscription
                              ? userDetailData?.subscription?.status
                              : ""}
                          </Tag>
                        </div>
                      </div>

                      {/* <div className="subscription-right">
                        <div className="cancel-subscription">
                          <img
                            src={cancelsub}
                            alt="Cancel Subscription"
                            className="plan-icon"
                          />
                          <span className="plan-name cancel-label">
                            Cancel Subscription
                          </span>
                        </div>
                        <div className="action-buttons">
                          <Button className="custom-button">
                            Renew Plan
                            <img
                              src={rightupicon}
                              alt="Renew Icon"
                              className="button-icon"
                            />
                          </Button>

                          <Button className="custom-button">
                            Upgrade Plan
                            <img
                              src={rightupicon}
                              alt="Upgrade Icon"
                              className="button-icon"
                            />
                          </Button>
                        </div>
                      </div> */}
                    </div>

                    <div className="date-expairy">
                      <div className="pack-expiry ">
                        <img
                          src={packexpiry}
                          alt="Pack Expiry Icon"
                          className="expiry-icon"
                        />
                        <div className="expiry-text">
                          <div className="expiry-label">Pack Expiry in</div>
                          <p className="expiry-date">
                            {calculateDaysFromCurrentToEnd(
                              userDetailData?.subscription?.end_date
                            ) + " days left"}{" "}
                          </p>
                        </div>
                      </div>

                      <Divider
                        type="vertical"
                        style={{ marginTop: "25px", height: "10px" }}
                      />

                      <div className="pack-expiry">
                        <img
                          src={packexpiry}
                          alt="Pack Expiry Icon"
                          className="expiry-icon"
                        />
                        <div className="expiry-text">
                          <div className="expiry-label">Renew Duo on</div>
                          <p className="expiry-date">
                            {userDetailData?.subscription?.end_date
                              ? dayjs(
                                  userDetailData.subscription.end_date
                                ).format("DD-MM-YYYY")
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Empty description={"Not Found"} />
                )}
              </>
            }
          </>
        );

      case "billing-history":
        return (
          <>
            {billingHistoryData && billingHistoryData.length > 0 ? (
              <>
                <div className="plan-container">
                  <div className="plan-details">
                    <div className="plan-info">
                      <img
                        src={billinghistory}
                        alt="Billing Plan Icon"
                        className="plan-icon"
                      />
                      <span className="plan-title">Billing History</span>
                    </div>
                  </div>
                  {/* <Button className="download-button" onClick={handleDownload}>
                    Download Invoice
                  </Button> */}
                </div>

                {billingHistoryData.map((item, index) => (
                  <div key={index} className="date-expairy-custom">
                    <div className="pack-expiry">
                      <img
                        src={packexpiry}
                        alt="Invoice Icon"
                        className="expiry-icon"
                      />
                      <div className="expiry-text">
                        <div className="expiry-label">Invoice ID</div>
                        <p className="expiry-date">
                          {item.invoice_number ? item.invoice_number : "-"}
                        </p>
                      </div>
                    </div>

                    <Divider type="vertical" className="custom-divider" />

                    <div className="pack-expiry">
                      <img src={bill} alt="Bill Icon" className="expiry-icon" />
                      <div className="expiry-text">
                        <div className="expiry-label">Bill Amount</div>
                        <p className="expiry-date">
                          {item.total ? item.total : "-"}
                        </p>
                      </div>
                    </div>

                    <Divider type="vertical" className="custom-divider" />

                    <div className="pack-expiry">
                      <img
                        src={packexpiry}
                        alt="Payment Icon"
                        className="expiry-icon"
                      />
                      <div className="expiry-text">
                        <div className="expiry-label">Payment Date</div>
                        <p className="expiry-date">
                          {item.invoice_date
                            ? dayjs(item.invoice_date).format("DD-MM-YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <Divider type="vertical" className="custom-divider" />

                    <div className="pack-expiry">
                      <img
                        src={payment}
                        alt="Payment Method Icon"
                        className="expiry-icon"
                      />
                      <div className="expiry-text">
                        <div className="expiry-label">Payment status</div>
                        <p className="expiry-date">
                          {item.status
                            ? item.status.charAt(0).toUpperCase() +
                              item.status.slice(1).toLowerCase()
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <Empty description={"Not Found"} />
            )}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Spin spinning={userDetailLoader}>
        <div className="view-property">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li"
                    align="middle"
                    title="View Profile "
                    bordered={false}
                    style={{ width: "100%" }}
                  >
                    <Image
                      preview={{ mask: "" }}
                      src={userDetailData.profile_pic}
                      style={{
                        border: "3px solid #FF9A3E",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      width={100}
                      height={100}
                    />
                    <p className="user-name">{`${
                      userDetailData.first_name + " " + userDetailData.last_name
                    } (${userDetailData.roles})`}</p>
                    <p className="user-email">{userDetailData.email}</p>
                  </Card>
                </Col>
                {/* {favoritePropertyData && favoritePropertyData.properties && favoritePropertyData.properties.length > 0 ? */}
                <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li favourite-property-card"
                    align="middle"
                    title="Favourite Properties"
                    bordered={false}
                    style={{ width: "100%", padding: "0px" }}
                  >
                    <List
                      style={{
                        maxHeight: 400,
                        height: "auto",
                        overflow: "auto",
                      }}
                      className="scrollable-list"
                      itemLayout="horizontal"
                      dataSource={favoritePropertyData.properties}
                      renderItem={(item, index) => (
                        <List.Item>
                          <div>
                            <Avatar
                              shape="square"
                              size={64}
                              src={item.property_images[0]}
                            />
                          </div>
                          <div style={{ marginLeft: "15px" }}>
                            <a
                              href={`/view-property/${item._id}`}
                            >{`${item.street_address}, ${item.city}, ${item.state}, ${item.country}`}</a>
                            <br />
                            <span>
                              Date Added :{" "}
                              <span style={{ color: "#443527" }}>
                                {dayjs(item.createdAt).format("DD/MM/YYYY")}
                              </span>
                            </span>
                            <br />
                            <span>${item.asking_price}</span>
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={16}>
              <Card
                className="body-padding form-card-border list-layout-li pb-"
                title="More Details"
                bordered={false}
                style={{ width: "100%" }}
              >
                <Descriptions
                  title=""
                  layout="horizontal"
                  colon={true}
                  bordered
                  column={1}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <Descriptions.Item
                        key={index}
                        label={item.label}
                        style={{
                          background:
                            index % 2 !== 0 ? "rgba(239, 239, 239, 1)" : "",
                        }} // Set color dynamically or default to black
                      >
                        {item.value ? item.value : "-"}
                      </Descriptions.Item>
                    ))}
                </Descriptions>
                <br />
                <br />
                <Link to={`/update-user/${userDetailData._id}`}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                  >
                    Update
                  </Button>
                </Link>

                <Popconfirm
                  okButtonProps={{
                    loading: deleteUserLoader,
                  }}
                  placement="topLeft"
                  title="Are you sure to delete this User?"
                  onConfirm={() => confirm(userDetailData._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                    icon={<DeleteOutlined />}
                    type="danger"
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Card>

              {/* <div className="mt-4"> */}
              <Card
                style={{ marginTop: "10px", border: "1px solid #e0e0e0" }}
                className="body-padding"
              >
                <div className="container">
                  <div className="tab-container">
                    {items.map(({ key, label }) => (
                      <div
                        key={key}
                        className={`tab-item ${
                          selectItem === key ? "selected" : ""
                        }`}
                        onClick={() => setSelectItem(key)}
                      >
                        <h1 className="mb-[7px]">{label}</h1>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="content">{showSubscriptionTab()}</div>
              </Card>
              {/* </div> */}
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetailData: state.user.userDetailData,
    userDetailLoader: state.user.userDetailLoader,
    deleteUserLoader: state.user.deleteUserLoader,
    favoritePropertyData: state.favouriteProperty.favoritePropertyData,
    favoritePropertyLoader: state.favouriteProperty.favoritePropertyLoader,
    billingHistoryData: state.user.billingHistoryData,
    userDetailDataSubscription: state.user.userDetailDataSubscription,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionDeleteUser,
  actionGetFavouriteProperty,
  actionGetAllBillingHistory,
})(UserDetail);
