import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../assets/img/icon/file.png";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { actionAddBusiness } from "../../store/actions/businessAction";
import { actionGetAllUser } from "../../store/actions/userAction";
const { Search } = Input;
const { TextArea } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateBusiness = (props) => {
  const { actionAddBusiness,
    addBusinessLoader,
    actionGetAllUser,
    usersData,
  } = props;
  const [userPicUrl, setUserPicUrl] = useState();
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const [form] = Form.useForm();

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setUserPicUrl(info.file.response.file.path);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      business_logo: userPicUrl
        ? userPicUrl.split("/").reverse()[0]
        : "",
      user: values.user ? values.user : "",
    };
    actionAddBusiness(request, Navigate);
    // console.log(request);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  useEffect(() => {
    actionGetAllUser(0, 500, null, ['user', 'admin', 'agent'], true);
  }, []);

  const users = [];
  usersData && usersData.map((user, index) => {
    if (user) {
      users.push({
        key: index,
        label: user.first_name + " " + user.last_name,
        value: user._id,
      });
    }
  })

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="form-card-border"
            title={<h3>Create Business</h3>}
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  // initialValues={{ is_active: true }}
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={24}>
                      <Form.Item
                        label="Select Owner"
                        colon={false}
                        name="user"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Owner!",
                          },
                        ]}
                      >
                        <Select
                          // mode="multiple"
                          placeholder="Select Owner"
                          allowClear
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          filterOption={filterOption}
                          options={users}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Business Name"
                        name="business_name"
                        // normalize={(value) =>
                        //   value.replace(/[^a-zA-Z]/g, "").trim()
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Please input your business name!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Please enter maximum 15 characters for Business Name"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter Business Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Business Email"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="business_email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            // message: "Please enter email!",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input
                          // autoFocus="none"
                          type={"text"}
                          placeholder="Enter Email"
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={3}>
                      <Form.Item
                        label="Mobile"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="country_phone_code"
                        rules={[
                          {
                            required: true,
                            message: "Please select country code.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="+91"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "+91",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      {/* <p style={{ color: "transparent" }}>transparent </p> */}
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="business_mobile"
                        label="Business Mobile no"
                        rules={[
                          {
                            required: true,
                            message: "Please enter business mobile number!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (value && value.length == 10) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(
                          //       new Error("Invalid Mobile Number!")
                          //     );
                          //   },
                          // }),
                        ]}
                      >
                        <Input
                          maxLength={2}
                          // autoFocus="none"
                          type={"number"}
                          placeholder="Enter Business Mobile Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Office Phone no"
                        colon={false}
                        name="business_office_phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter office phone number!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (value && value.length == 10) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(
                          //       new Error("Invalid Phone Number!")
                          //     );
                          //   },
                          // }),
                        ]}
                      >
                        <Input type={"number"} placeholder="Enter Office Mob no." />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Business Website"
                        colon={false}
                        name="business_website"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.trim() === '') {
                                // If no value is added, return success
                                return Promise.resolve();
                              }
                              if (/^(https?:\/\/)?[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(value)) {
                                // If value is a valid URL, return success
                                return Promise.resolve();
                              }
                              // If value is not a valid URL, return error message
                              return Promise.reject(new Error('Invalid Website URL'));
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Enter Business website" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Broker Name"
                        colon={false}
                        name="broker_name"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Broker Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Broker Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Business Address"
                        colon={false}
                        name="business_address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter business address!",
                          },
                        ]}
                      >
                        {/* <Input.Password placeholder="Enter Business Address" /> */}
                        <TextArea
                          style={{
                            height: 100,
                            resize: "none",
                          }}
                          placeholder="Business Address"
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col span={24}>
                      <br />
                      <Form.Item
                        normalize={(value) => value.trim()}
                        name="business_logo"
                        rules={[
                          {
                            required: userPicUrl ? false : true,
                            message: !userPicUrl && "Please select image!",
                          },
                        ]}
                      >
                        <div>
                          <Search
                            readOnly
                            placeholder="Image(200px x 200px)"
                            enterButton={
                              <>
                                <ImgCrop
                                  rotationSlider
                                  aspect={4 / 3}
                                  fillColor="white"
                                >
                                  <Upload
                                    name="file"
                                    maxCount={1}
                                    showUploadList={false}
                                    action={BASE_URL_UPLOAD}
                                    headers={{
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                          "scoutheimAdminToken"
                                        ),
                                    }}
                                    onChange={handleChangeUserPic}
                                  >
                                    <Image src={FileIcon} preview={false} />
                                  </Upload>
                                </ImgCrop>
                              </>
                            }
                          />
                        </div>
                        {userPicUrl && (
                          <Image
                            src={userPicUrl}
                            alt="file"
                            preview={false}
                            width={200}
                            style={{ marginTop: "10px" }}
                          />
                        )}
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      {/* <br /> */}
                      <Form.Item
                        normalize={(value) => value.trim()}
                        name="business_logo"
                        label="Business Logo"
                        // rules={[
                        //   {
                        //     required: userPicUrl ? false : true,
                        //     message: !userPicUrl && "Please select image!",
                        //   },
                        // ]}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <div>
                          <>
                            <ImgCrop
                              rotationSlider
                              aspect={4 / 3}
                              fillColor="white"
                            >
                              {/* <Upload
                                    name="file"
                                    maxCount={1}
                                    showUploadList={false}
                                    action={BASE_URL_UPLOAD}
                                    headers={{
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                          "scoutheimAdminToken"
                                        ),
                                    }}
                                    onChange={handleChangeUserPic}
                                  >
                                    <Image src={FileIcon} preview={false} />
                                  </Upload> */}
                              <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={BASE_URL_UPLOAD}
                                headers={{
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem(
                                      "scoutheimAdminToken"
                                    ),
                                }}
                                onChange={handleChangeUserPic}
                              >
                                {userPicUrl ? (
                                  <img
                                    src={userPicUrl}
                                    alt="avatar"
                                    style={{
                                      width: '85%',
                                      height: '85%',
                                    }}
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </Upload>
                            </ImgCrop>
                          </>
                        </div>
                        {/* {userPicUrl && (
                          <Image
                            src={userPicUrl}
                            alt="file"
                            preview={false}
                            width={200}
                            style={{ marginTop: "10px" }}
                          />
                        )} */}
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addBusinessLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addBusinessLoader: state.business.addBusinessLoader,
    usersData: state.user.usersData,
  };
};

export default connect(mapStateToProps, {
  actionAddBusiness,
  actionGetAllUser,
})(CreateBusiness);
